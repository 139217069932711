import { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { InteractiveList } from "@circle-react-shared/InteractiveList";
import { Typography } from "@circle-react-uikit/Typography";

const ItemContent = ({ text, listItemText }) => (
  <Typography.LabelSm weight="medium">
    {listItemText || text}
  </Typography.LabelSm>
);

ItemContent.propTypes = {
  text: PropTypes.string,
  listItemText: PropTypes.string,
};

export const OptionsDropdown = forwardRef(
  ({ items, onSelect, loading, notFoundContent, direction, ...rest }, ref) => (
    <div
      ref={ref}
      className={classnames("select-list bg-primary", `direction-${direction}`)}
      {...rest}
    >
      <InteractiveList
        loading={loading}
        getKey={item => item.value}
        getItemContent={ItemContent}
        items={items}
        onSelect={onSelect}
        notFoundContent={notFoundContent}
      />
    </div>
  ),
);
OptionsDropdown.displayName = "OptionsDropdown";

OptionsDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  notFoundContent: PropTypes.node,
  direction: PropTypes.oneOf(["up", "down"]),
};
