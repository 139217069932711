import { useLayoutEffect, useState } from "react";

export const CSRFTokenField = () => {
  const [csrfToken, setCsrfToken] = useState("");

  useLayoutEffect(() => {
    const elm = document.querySelector("meta[name='csrf-token']");
    if (elm) {
      const attr = elm.getAttribute("content");
      setCsrfToken(attr);
    }
  }, []);

  return <input type="hidden" name="authenticity_token" value={csrfToken} />;
};
