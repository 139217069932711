import classNames from "classnames";
import first from "lodash/first";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { Icon } from "@circle-react-shared/Icon";
import { MenuButton } from "./MenuButton";
import type { Option } from "./Option";
import { isSeparatorOption } from "./Option";

export interface ActiveLabelButtonProps {
  activeOptionValue?: string;
  options?: Option[];
  className?: string;
  labelColor?: string;
}

export const ActiveLabelButton = ({
  activeOptionValue,
  options = [],
  className,
  labelColor = "text-dark",
}: ActiveLabelButtonProps) => {
  const matchOption = options.find(
    option => option.value === activeOptionValue,
  );
  const firstOption = first(options);
  const activeOption = matchOption || firstOption;

  if (activeOption && isSeparatorOption(activeOption)) {
    return null;
  }

  return (
    <MenuButton
      className={classNames(
        className,
        "focus-visible:bg-tertiary hover:bg-tertiary !gap-1 !rounded-lg py-2 !pl-3 !pr-1.5 transition-colors duration-200",
      )}
    >
      <Typography.LabelSm color={labelColor} weight="medium">
        {activeOption?.label}
      </Typography.LabelSm>
      <Icon
        type="caret-down"
        aria-hidden="true"
        useWithStrokeCurrentColor
        className={classNames("h-5 w-5", labelColor)}
        viewBox="0 0 8 4"
      />
    </MenuButton>
  );
};
