import { orderBy } from "lodash";
import { activeStorageBlobUrl } from "@/react/helpers/urlHelpers";
import { APPEARANCE } from "@/react/providers";
import {
  EMOJI_RENDERER_TYPES,
  RENDER_IMG_TYPES,
} from "@circle-react-shared/EmojiRenderer/constants";
import { customEmojiIconUrl } from "@circle-react-uikit/EmojiPickerV3/data/icons";
import { customEmojiShapeUrl } from "@circle-react-uikit/EmojiPickerV3/data/shapes";
import { CUSTOM_COLOR_ORDER_FOR_SORTING } from "./constants";

export const customEmojiUrl = ({ customEmoji, appearance, defaultUrl }) => {
  if (!customEmoji) {
    return defaultUrl;
  }
  if (!RENDER_IMG_TYPES.includes(customEmoji.emoji_type)) {
    return null;
  }
  if (
    appearance === APPEARANCE.DARK &&
    customEmoji.emoji_type !== EMOJI_RENDERER_TYPES.icon
  ) {
    return null;
  }
  if (customEmoji.emoji_type === EMOJI_RENDERER_TYPES.custom) {
    return activeStorageBlobUrl({
      signed_id: customEmoji.signed_id,
      filename: customEmoji.filename,
    });
  }
  if (customEmoji.emoji_type === EMOJI_RENDERER_TYPES.icon) {
    return customEmojiIconUrl(customEmoji.identifier, appearance);
  }
  if (customEmoji.emoji_type === EMOJI_RENDERER_TYPES.shape) {
    return customEmojiShapeUrl(customEmoji.identifier);
  }
};

/**
 * Sorts an array of shape objects first by group, then by color.
 * The group order is: light shapes, gradient shapes, grey shapes, other shapes.
 * The color order is determined by the CUSTOM_COLOR_ORDER_FOR_SORTING object.
 * @param {Array} data - The array of shape objects to be sorted.
 * @returns {Array} - The sorted array of shape objects.
 */
export const sortShapesByGroupAndColor = data => {
  // Loop through each object in the data array
  data.forEach(obj => {
    let prefix = "";
    // Determine the prefix for the object based on its name
    if (obj.name.includes("light")) {
      prefix = "1-";
    } else if (obj.name.includes("gradient")) {
      prefix = "2-";
    } else if (obj.name.includes("grey")) {
      prefix = "3-";
    } else {
      prefix = "0-";
    }

    let colorPrefix = "";
    // Loop through each key-value pair in the CUSTOM_COLOR_ORDER_FOR_SORTING object
    Object.entries(CUSTOM_COLOR_ORDER_FOR_SORTING).forEach(([color, value]) => {
      // Check if the object's name includes the current color
      if (obj.name.includes(color)) {
        // If so, set the color prefix to the value associated with that color in the CUSTOM_COLOR_ORDER_FOR_SORTING object
        colorPrefix = value;
      }
    });

    // Update the object's name property to include the prefix and color prefix, and remove any existing prefix and color prefix from the name
    obj.name =
      prefix +
      colorPrefix +
      "-" +
      obj.name.replace(`${prefix}${colorPrefix}-`, "");
  });

  // Sort the data array by the updated name property and return the sorted array
  return orderBy(data, ["name"]);
};
