import PropTypes from "prop-types";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { APPEARANCE, useThemeContext } from "@/react/providers";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { EMOJI_RENDERER_TYPES } from "@circle-react-shared/EmojiRenderer/constants";
import { Icon } from "@circle-react-shared/Icon";
import {
  CUSTOM_ICON_DARK_DATA_SET,
  CUSTOM_ICON_LIGHT_DATA_SET,
} from "@circle-react-uikit/EmojiPickerV3/data";
import { customEmojiUrl } from "@circle-react-uikit/EmojiPickerV3/data/utils";
import { Form } from "@circle-react-uikit/Form";
import { DEFAULT_ICON } from "../FormEmojiPickerV3/constants";

export const EMOJI_FIELD_NAME = "emoji";

export const EmojiPickerWrapper = ({
  name = "custom_emoji",
  record,
  className,
  wrapperClassName,
  shouldEmojiPickerRenderAsPortal = true,
  resetToDefaultIcon = true,
}) => {
  const { isDarkAppearance, currentAppearance } = useThemeContext();
  const { setValue, watch, resetField } = useFormContext();
  const customEmoji = watch(name);
  const recordEmoji = watch(EMOJI_FIELD_NAME);
  const handleEmojiIconUpload = ({ status, response }) => {
    if (status === "completed" && response !== null) {
      setValue(
        name,
        {
          signed_id: response.signed_id,
          filename: response.filename,
          emoji_type: EMOJI_RENDERER_TYPES.custom,
        },
        { shouldDirty: true },
      );
    }
  };

  const handleRemoveEmoji = () => {
    if (resetToDefaultIcon) {
      return resetField(name, {
        defaultValue: DEFAULT_ICON,
      });
    }
    resetEmoji();
  };

  const resetEmoji = () => {
    resetField(EMOJI_FIELD_NAME, {
      defaultValue: null,
    });
    resetField(name, {
      defaultValue: {
        emoji: null,
        emoji_type: null,
        identifier: null,
      },
    });
  };

  return (
    <Form.EmojiPickerV3
      name={name}
      target={
        <EmojiRenderer
          wrapperClassName={classNames(
            "form-control__prefix__children",
            wrapperClassName,
          )}
          className={classNames("!w-6 !p-0", className)}
          emptyIcon={<Icon type="smile" />}
          emoji={customEmoji?.emoji || recordEmoji}
          customEmojiUrl={customEmojiUrl({
            customEmoji,
            appearance: APPEARANCE.LIGHT,
            defaultUrl: record?.custom_emoji_url,
          })}
          customEmojiDarkUrl={customEmojiUrl({
            customEmoji,
            appearance: APPEARANCE.DARK,
            defaultUrl: record?.custom_emoji_dark_url,
          })}
          appearance={currentAppearance}
          isWrapperElementClickable
        />
      }
      pickerAddOns={Object.values(EMOJI_RENDERER_TYPES).filter(
        value => value !== EMOJI_RENDERER_TYPES.icon,
      )}
      customIcons={
        isDarkAppearance
          ? CUSTOM_ICON_DARK_DATA_SET
          : CUSTOM_ICON_LIGHT_DATA_SET
      }
      onRemoveEmoji={handleRemoveEmoji}
      handleCustomIconUpload={handleEmojiIconUpload}
      shouldEmojiPickerRenderAsPortal={shouldEmojiPickerRenderAsPortal}
    />
  );
};

EmojiPickerWrapper.propTypes = {
  record: PropTypes.object,
  emptyIcon: PropTypes.element,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  resetToDefaultIcon: PropTypes.bool,
};

EmojiPickerWrapper.displayName = "Form.FormEmojiPickerV3Wrapper";
