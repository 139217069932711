import { useCallback, useEffect, useRef } from "react";
import type { ReactNode } from "react";
import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { Content } from "./Content";
import "./styles.scss";

export interface TagProps {
  autoScrollIntoView?: boolean;
  children?: ReactNode;
  className?: string;
  icon?: ReactNode;
  label?: string;
  onClose?: () => void;
  variant?: "primary" | "secondary";
}

export const Tag = ({
  children,
  className = "member-badge",
  onClose,
  label,
  icon = null,
  autoScrollIntoView = false,
  variant = "primary",
}: TagProps) => {
  // I don't think this logic should be at this level
  const divRef = useRef<HTMLDivElement>(null);
  const scrollToDivRef = useCallback(() => {
    setTimeout(
      function () {
        divRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }.bind(this),
      10,
    );
  }, [divRef]);

  useEffect(() => {
    if (autoScrollIntoView) {
      scrollToDivRef();
    }
  }, [autoScrollIntoView, scrollToDivRef]);

  const classNames = classnames(
    className,
    "react-tag",
    { primary: variant === "primary" },
    { secondary: variant === "secondary" },
  );

  return (
    <div ref={divRef} className={classNames}>
      {children || (
        <Content
          label={label}
          onClose={onClose}
          icon={icon}
          closeIconAriaLabel={`${t("remove")} ${label}`}
        />
      )}
    </div>
  );
};
