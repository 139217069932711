import { t } from "@/i18n-js/instance";
import { EMAIL_REGEX } from "@circle-react/components/OnboardingPage/helpers/constants";
import type { CommunityMember } from "@circle-react/types";

interface AddableOption {
  value: string;
  text: string;
  listItemText: string;
}

interface FormattedMemberData {
  value?: number;
  text: string;
  member: CommunityMember;
}

type CommunityMemberValueField = "id" | "user_id";

export const getAddableOption = (value: string): AddableOption | undefined => {
  if (!value) return undefined;

  const isValidEmail = EMAIL_REGEX.test(value);
  if (!isValidEmail) return undefined;

  return {
    value,
    text: value,
    listItemText: `${t("invite")} ${value}`,
  };
};

export const formatCommunityMemberData = (
  communityMembers: CommunityMember[],
  valueField: CommunityMemberValueField,
  getCommunityMemberText: (communityMember: CommunityMember) => string,
): FormattedMemberData[] =>
  communityMembers.map(communityMember => ({
    value: communityMember[valueField],
    text: getCommunityMemberText(communityMember),
    member: communityMember,
  }));
