import { Controller, useFormContext } from "react-hook-form";
import { SimpleDropdown } from "@circle-react/components/shared/uikit/SimpleDropdown";

export const FormAnimatedSelect = ({ name, options, rules = {}, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref, name } }) => (
        <SimpleDropdown
          {...props}
          menuItems={options}
          onBlur={onBlur}
          name={name}
          ref={ref}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

FormAnimatedSelect.displayName = "Form.AnimatedSelect";
