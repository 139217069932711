export const dropdowListInOut = {
  enter: "transition ease-out duration-75",
  enterFrom: "transform opacity-0 scale-95",
  enterTo: "transform opacity-100 scale-100",
  leave: "transition ease-in duration-75",
  leaveFrom: "transform opacity-100 scale-100",
  leaveTo: "transform opacity-0 scale-95",
} as const;

export const popOverInOut = {
  enter: "transition duration-100 ease-out",
  enterFrom: "transform scale-95 opacity-0",
  enterTo: "transform scale-100 opacity-100",
  leave: "transition duration-75 ease-out",
  leaveFrom: "transform scale-100 opacity-100",
  leaveTo: "transform scale-95 opacity-0",
} as const;

export const slideLeftAnimations = {
  enter: "ease-in duration-300",
  enterFrom: "-translate-x-full",
  enterTo: "translate-x-0",
  leave: "ease-out duration-300",
  leaveFrom: "translate-x-0",
  leaveTo: "-translate-x-full",
} as const;

export const slideRightAnimations = {
  enter: "ease-in duration-300",
  enterFrom: "translate-x-full",
  enterTo: "translate-x-0",
  leave: "ease-out duration-300",
  leaveFrom: "translate-x-0",
  leaveTo: "translate-x-full",
} as const;

export const defaultModalAnimations = {
  enter: "ease-out duration-300",
  enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
  enterTo: "opacity-100 translate-y-0 sm:scale-100",
  leave: "ease-in duration-200",
  leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
  leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
} as const;

export const slideBottomUpAnimations = {
  enter: "ease-in duration-300",
  enterFrom: "opacity-50 translate-y-48",
  enterTo: "opacity-100 translate-y-0",
  leave: "ease-out duration-200",
  leaveFrom: "opacity-100 translate-y-0",
  leaveTo: "opacity-50 translate-y-48",
} as const;

export const fadeInAnimations = {
  enter: "ease-in duration-300",
  enterFrom: "opacity-50",
  enterTo: "opacity-100",
  leave: "ease-out duration-200",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-50",
} as const;

export const defaultOverlayEnterAnimation = {
  enter: "ease-out duration-200",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
} as const;

export const defaultOverlayLeaveAnimation = {
  leave: "ease-in duration-200",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
} as const;
