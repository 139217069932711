import { Controller, useFormContext } from "react-hook-form";
import { MemoPicker } from "./MemoPicker";

export const LocationPicker = ({ name, rules, ...passThroughProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, name } }) => (
        <MemoPicker
          {...passThroughProps}
          name={name}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};

LocationPicker.displayName = "Form.LocationPicker";
