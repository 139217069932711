import { ModalBackButton } from "./ModalBackButton";
import { ModalBody } from "./ModalBody";
import { ModalCloseButton } from "./ModalCloseButton";
import { ModalContent } from "./ModalContent";
import { useModalData } from "./ModalDataProvider";
import { ModalDrawerContent } from "./ModalDrawerContent";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";
import { ModalHeaderActions } from "./ModalHeaderActions";
import { useCurrentModalLevel } from "./ModalLevelContext";
import { useModalNesting } from "./ModalNestingContext";
import { ModalOverlay } from "./ModalOverlay";
import { ModalRoot } from "./ModalRoot";
import { ModalTitle } from "./ModalTitle";

export const Modal = Object.assign(ModalRoot, {
  Content: ModalContent,
  Footer: ModalFooter,
  Header: ModalHeader,
  Overlay: ModalOverlay,
  Body: ModalBody,
  CloseButton: ModalCloseButton,
  BackButton: ModalBackButton,
  HeaderActions: ModalHeaderActions,
  Title: ModalTitle,
  DrawerContent: ModalDrawerContent,
});

// eslint-disable-next-line no-barrel-files/no-barrel-files -- Part of the Modal API
export { useCurrentModalLevel, useModalNesting, useModalData };
