import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import { useEmojiPickerContext } from "./EmojiPickerContext";
import { EmojiPickerWrapper } from "./EmojiPickerWrapper";
import { Header } from "./Header";
import CustomPicker from "./Pickers/Custom";
import EmojiMartPicker from "./Pickers/EmojiMart";
import { updatePickerStyles } from "./Pickers/EmojiMart/utils";

export const EmojiPopOver = () => {
  const {
    as,
    setTargetElement,
    setPopperElement,
    styles,
    attributes,
    shouldRenderEmojiMartPicker,
    isCustomPickerTabActive,
    target,
    targetWrapperClassName,
    shouldEmojiPickerRenderAsPortal,
    popoverPanelClassName,
    shouldShowEmojiPreview,
    isCustomFileUploadProcessing,
    portalTo,
  } = useEmojiPickerContext();

  const handlePickerVisibility = (open: any) => {
    updatePickerStyles();
    if (
      !isCustomPickerTabActive ||
      (isCustomPickerTabActive && !isCustomFileUploadProcessing)
    ) {
      return open;
    }
    if (isCustomPickerTabActive && isCustomFileUploadProcessing) {
      return isCustomFileUploadProcessing;
    }
  };

  return (
    <Popover as="div" className="relative" data-testid="emoji-picker">
      {({ close, open }) => (
        <>
          <Popover.Button
            as={as}
            ref={setTargetElement}
            className={classNames("inline-flex", targetWrapperClassName)}
            data-testid="emoji-picker-button"
          >
            {target}
          </Popover.Button>
          <EmojiPickerWrapper
            open={open}
            shouldEmojiPickerRenderAsPortal={shouldEmojiPickerRenderAsPortal}
            portalTo={portalTo}
          >
            <Transition
              show={handlePickerVisibility(open)}
              as="div"
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className={classNames(
                  "bg-primary border-primary z-[9999] w-[24rem] rounded-lg border shadow-lg md:w-[28rem]",
                  popoverPanelClassName,
                )}
                data-testid="emoji-popover-panel"
              >
                <>
                  <Header />
                  <div
                    className={classNames({
                      "py-3": !shouldShowEmojiPreview,
                      "pt-3": shouldShowEmojiPreview,
                      "px-1": shouldRenderEmojiMartPicker,
                      "px-4 pb-3": !shouldRenderEmojiMartPicker,
                    })}
                  >
                    {shouldRenderEmojiMartPicker && (
                      <EmojiMartPicker close={close} />
                    )}
                    {isCustomPickerTabActive && <CustomPicker close={close} />}
                  </div>
                </>
              </Popover.Panel>
            </Transition>
          </EmojiPickerWrapper>
        </>
      )}
    </Popover>
  );
};
