import { forwardRef } from "react";
import { WithDescription } from "./WithDescription";
import type { WithDescriptionProps } from "./WithDescription";

interface RadioProps extends WithDescriptionProps {
  variant?: string;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ variant = "description", ...rest }, ref) => {
    if (variant === "description") {
      return <WithDescription {...rest} ref={ref} />;
    }

    throw new Error(`Unknown variant: ${variant}`);
  },
);

Radio.displayName = "Radio";
