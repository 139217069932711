import type { ReactNode } from "react";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import type { Option } from "../types";

export interface OptionItemProps {
  option: Option;
  checked: boolean;
  checkedIcon: ReactNode;
  labelOutside: boolean;
  fullWidth: boolean;
  disabled: boolean;
  optionSize?: "sm" | "md";
  optionAlignment?: "center" | "left" | "row";
}

export const OptionItem = ({
  option: { description, label, icon },
  checked,
  checkedIcon,
  labelOutside,
  fullWidth,
  disabled,
  optionSize = "md",
  optionAlignment = "center",
}: OptionItemProps) => {
  const isLabelInside = !labelOutside && Boolean(label);
  const isLabelOutside = labelOutside && Boolean(label);
  const hasDescription = Boolean(description);

  return (
    <div
      className={classNames("option-group__option-wrapper", {
        disabled: disabled && !checked,
        "!items-start": optionAlignment === "left",
      })}
    >
      <div
        className={classNames("option-group__option", {
          active: checked,
          "!items-start": optionAlignment === "left",
          "!flex !flex-row !items-center !justify-start !gap-3 sm:!justify-center":
            optionAlignment === "row",
        })}
      >
        {icon?.type && (
          <div
            className={classNames("option__icon", {
              "option__icon--full-width": fullWidth,
              "option__icon--sm": optionSize === "sm",
              active: checked,
            })}
          >
            <Icon
              type={icon.type}
              viewBox={icon.viewBox}
              className={icon.className}
            />
          </div>
        )}
        {isLabelInside && (
          <div
            className={classNames("flex flex-row items-start justify-between", {
              "w-full": Boolean(checkedIcon),
            })}
          >
            <p
              className={classNames("option-group__option-label", {
                "!text-left": optionAlignment === "left",
              })}
            >
              {label}
            </p>
            {checked && checkedIcon}
          </div>
        )}
        {hasDescription && (
          <p
            className={classNames("option-group__option-description", {
              "!text-left": optionAlignment === "left",
            })}
          >
            {description}
          </p>
        )}
      </div>
      {isLabelOutside && (
        <p
          className={classNames(
            "option-group__option-label text-dark font-medium",
            { "!text-left": optionAlignment === "left" },
          )}
        >
          {label}
        </p>
      )}
    </div>
  );
};
