import { UserImage } from "@circleco/ui-kit";
import { usePunditUserContext } from "@circle-react/contexts/punditUserContext";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { Typography } from "../Typography";

interface MemberListItemProps {
  member: any;
}

export const MemberListItem = ({ member }: MemberListItemProps) => {
  const { currentCommunityMember } = usePunditUserContext();

  return (
    <div className="flex items-start px-2">
      <UserImage
        src={member.avatar_url}
        name={member.name}
        size="7"
        isFullRounded
      />

      <div className="ml-3 flex min-w-0 flex-col">
        <Typography.LabelSm weight="medium">{member.name}</Typography.LabelSm>
        {isCommunityAdmin(currentCommunityMember) && (
          <Typography.LabelXs>{member.email}</Typography.LabelXs>
        )}
      </div>
    </div>
  );
};
