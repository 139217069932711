import { createContext, useContext } from "react";
import type { ReactNode } from "react";

export interface ModalLevelContextShape {
  currentLevel: number;
}

const ModalLevel = createContext<ModalLevelContextShape | null>(null);

ModalLevel.displayName = "ModalLevel";

const useModalLevelContext = () => useContext(ModalLevel);

export const useCurrentModalLevel = () => {
  const levelContext = useModalLevelContext();

  if (!levelContext) return 0;

  return levelContext.currentLevel;
};

export interface ModalLevelContextProps {
  children: ReactNode;
}

export const ModalLevelContext = ({ children }: ModalLevelContextProps) => {
  const level = useCurrentModalLevel();
  return (
    <ModalLevel.Provider value={{ currentLevel: level + 1 }}>
      {children}
    </ModalLevel.Provider>
  );
};
