import classNames from "classnames";

export interface ScoreElementProps {
  icon: any;
  label: string;
  onClick: () => void;
  selected?: boolean;
}

export const ScoreElement = ({
  icon,
  label,
  onClick,
  selected,
}: ScoreElementProps) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames("form-score-picker__score", {
      "form-score-picker__score--selected": selected,
    })}
  >
    <div className="form-score-picker__score__icon">{icon}</div>
    <div className="form-score-picker__score__label">{label}</div>
  </button>
);
