import { forwardRef } from "react";
import type { ComponentPropsWithRef, ReactNode } from "react";
import { useRef } from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { isFunction, noop } from "lodash";
import { useClickAway } from "react-use";
import {
  defaultModalAnimations,
  fadeInAnimations,
  slideBottomUpAnimations,
} from "@circle-react-uikit/animations";
import { useModalData } from "./ModalDataProvider";

// Values in maximum width, mobile is always fullscreen
export const MODAL_SIZES = {
  sm: "sm", // 384px
  md: "md", // 480px
  lg: "lg", // 624px
  xl: "xl", // 800px
  "2xl": "2xl", // 1024px
  full: "full", // 95%
  screen: "screen", // 100%
  // eslint-disable-next-line no-restricted-syntax -- False positive, not a translatable string
  screenWithHeader: "screen-with-header", // 100%
  auto: "auto", // 100%
} as const;

export const POSITIONS = {
  default: "default",
  bottom: "bottom",
  top: "top",
} as const;

export const ANIMATIONS = {
  default: defaultModalAnimations,
  slideBottomUp: slideBottomUpAnimations,
  fadeIn: fadeInAnimations,
} as const;

export type ModalAnimationType = keyof typeof ANIMATIONS;

export type ModalSizeType = (typeof MODAL_SIZES)[keyof typeof MODAL_SIZES];

export type ModalPositionType = keyof typeof POSITIONS;

export interface ModalContentProps extends ComponentPropsWithRef<"div"> {
  isOpen?: boolean;
  onClose?: () => void;
  children: ReactNode;
  className?: string;
  wrapperClassName?: string;
  shouldCloseOnOutsideClick?: boolean;
  size?: ModalSizeType;
  animations?: ModalAnimationType;
  position?: ModalPositionType;
}

export const ModalContent = forwardRef<HTMLDivElement, ModalContentProps>(
  (
    {
      isOpen = false,
      onClose = noop,
      children,
      className = "",
      wrapperClassName = "",
      shouldCloseOnOutsideClick = false,
      size = MODAL_SIZES.md,
      animations = "default",
      position = "default",
      ...rest
    },
    ref,
  ) => {
    const clickAwayRef = useRef(null);
    const { contentPortalId, setContentPortalElement } = useModalData();
    const animationsStyles = ANIMATIONS[animations] || defaultModalAnimations;

    const closeOnOutsideClick = () =>
      shouldCloseOnOutsideClick && isOpen && isFunction(onClose) && onClose();

    useClickAway(clickAwayRef, closeOnOutsideClick);

    return (
      <div
        className={classNames(
          "flex h-full w-screen justify-center",
          {
            "sm:items-start":
              size === MODAL_SIZES.xl || size === MODAL_SIZES["2xl"],
            "sm:items-center":
              size !== MODAL_SIZES.xl && size !== MODAL_SIZES["2xl"],
          },
          {
            "items-center": position === POSITIONS.default,
            "items-end": position === POSITIONS.bottom,
            "items-start": position === POSITIONS.top,
          },
          wrapperClassName,
        )}
        ref={clickAwayRef}
        data-testid="modal-content"
      >
        <Transition.Child
          as="div"
          className={classNames(
            "bg-primary h-max-screen-dynamic relative flex w-full min-w-[300px] flex-col overflow-auto text-left shadow-xl transition-all",
            {
              "h-full sm:mx-5 sm:h-auto sm:max-h-[80vh]":
                size !== MODAL_SIZES.screen &&
                size !== MODAL_SIZES.full &&
                size !== MODAL_SIZES.screenWithHeader,
              "mx-0 h-full rounded-none sm:rounded-lg":
                size !== "screen" && size !== MODAL_SIZES.screenWithHeader,
              "h-full sm:w-[384px]": size === MODAL_SIZES.sm,
              "h-full sm:w-[480px]": size === MODAL_SIZES.md,
              "h-full sm:w-[624px]": size === MODAL_SIZES.lg,
              "h-full sm:mt-[10vh] sm:w-[800px]": size === MODAL_SIZES.xl,
              "h-full sm:mt-[10vh] sm:w-[1024px]": size === MODAL_SIZES["2xl"],
              "h-full sm:h-[95%] sm:w-[95%]": size === MODAL_SIZES.full,
              "h-screen-dynamic w-screen sm:rounded-none":
                size === MODAL_SIZES.screen,
              "h-full w-screen self-end rounded-none md:h-[calc(100%-2.5rem)] md:rounded-t-2xl":
                size === MODAL_SIZES.screenWithHeader,
              "h-auto w-full rounded-t-2xl": size === MODAL_SIZES.auto,
            },
            className,
          )}
          data-testid="modal"
          ref={ref}
          {...rest}
          {...animationsStyles}
        >
          {children}
        </Transition.Child>
        {contentPortalId && (
          <div
            ref={setContentPortalElement}
            className="h-0 w-0"
            id={contentPortalId}
            data-testid="modal-content-portal"
          />
        )}
      </div>
    );
  },
);

ModalContent.displayName = "ModalContent";
