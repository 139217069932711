import { forwardRef } from "react";
import type { ComponentPropsWithRef, ReactNode } from "react";
import { useRef } from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { isFunction, noop } from "lodash";
import { useClickAway } from "react-use";
import {
  slideLeftAnimations,
  slideRightAnimations,
} from "@circle-react-uikit/animations";
import { useModalData } from "./ModalDataProvider";

const DRAWER_POSITIONS = {
  left: "left",
  right: "right",
} as const;

export type ModalDrawerPositionType = keyof typeof DRAWER_POSITIONS;

export const DRAWER_ANIMATIONS = {
  slideLeft: slideLeftAnimations,
  slideRight: slideRightAnimations,
} as const;

export type ModalDrawerAnimationType = keyof typeof DRAWER_ANIMATIONS;

export type ModalSizeType = "sm" | "md" | "lg" | "xl";

export interface ModalDrawerContentProps extends ComponentPropsWithRef<"div"> {
  children: ReactNode;
  className?: string;
  drawerPosition?: ModalDrawerPositionType;
  isOpen?: boolean;
  onClose?: () => void;
  shouldCloseOnOutsideClick?: boolean;
  size?: ModalSizeType;
  wrapperClassName?: string;
}

export const ModalDrawerContent = forwardRef<
  HTMLDivElement,
  ModalDrawerContentProps
>(
  (
    {
      children,
      className = "",
      drawerPosition = DRAWER_POSITIONS.right,
      isOpen = false,
      onClose = noop,
      shouldCloseOnOutsideClick = false,
      size = "md",
      wrapperClassName = "",
      ...rest
    },
    ref,
  ) => {
    const clickAwayRef = useRef<HTMLDivElement | null>(null);
    const { contentPortalId, setContentPortalElement } = useModalData();
    const animationsStyles =
      drawerPosition === DRAWER_POSITIONS.left
        ? slideLeftAnimations
        : slideRightAnimations;

    const closeOnOutsideClick = () =>
      shouldCloseOnOutsideClick && isOpen && isFunction(onClose) && onClose();

    useClickAway(clickAwayRef, closeOnOutsideClick);

    return (
      <div
        className={classNames(
          "flex h-full w-screen",
          {
            "justify-start": drawerPosition === DRAWER_POSITIONS.left,
            "justify-end": drawerPosition === DRAWER_POSITIONS.right,
          },
          wrapperClassName,
        )}
        data-testid="modal-drawer-content"
        ref={clickAwayRef}
      >
        <Transition.Child
          as="div"
          className={classNames(
            "bg-primary relative flex h-full w-full flex-col overflow-auto text-left shadow-xl transition-all",
            {
              "lg:w-[18rem]": size === "sm",
              "lg:w-[26rem]": size === "md",
              "lg:w-[40rem]": size === "lg",
              "lg:w-[64rem]": size === "xl",
            },
            className,
          )}
          data-testid="modal-drawer"
          ref={ref}
          {...rest}
          {...animationsStyles}
        >
          {children}
        </Transition.Child>
        {contentPortalId && (
          <div
            ref={setContentPortalElement}
            className="h-0 w-0"
            id={contentPortalId}
            data-testid="modal-drawer-content-portal"
          />
        )}
      </div>
    );
  },
);

ModalDrawerContent.displayName = "ModalDrawerContent";
