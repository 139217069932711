import { useEffect } from "react";
import classNames from "classnames";
import isFunction from "lodash/isFunction";
import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { useDirectUpload } from "@circle-react/hooks/useDirectUpload";
import { useEmojiPickerContext } from "../../../EmojiPickerContext";
import { ALLOWED_FILE_TYPES } from "../constants";

interface FileUploaderProps {
  close: () => void;
}

export const FileUploader = ({ close }: FileUploaderProps) => {
  const { uploadFile, progress, status, response, isError } = useDirectUpload();

  const {
    handleCustomIconUpload,
    shouldCloseOnSelect,
    setIsCustomFileUploadProcessing,
  } = useEmojiPickerContext();

  useEffect(() => {
    isFunction(handleCustomIconUpload) &&
      handleCustomIconUpload({ status, progress, response, isError });

    if (status === "uploading") {
      setIsCustomFileUploadProcessing(true);
    }

    if (status === "completed" && response && shouldCloseOnSelect) {
      setIsCustomFileUploadProcessing(false);
      setTimeout(() => {
        close();
      }, 1000);
    }
  }, [status, progress, response, isError]);

  const UPLOAD_BUTTON_CTA: any = {
    uploading: t("emoji_picker_v3.custom_icon.cta_uploading"),
    completed: t("emoji_picker_v3.custom_icon.cta_completed"),
    idle: t("emoji_picker_v3.custom_icon.file_upload.cta"),
  };

  return (
    <>
      <input
        type="file"
        id="custom-icon-upload"
        className="hidden"
        onChange={e => uploadFile(e.target?.files?.[0])}
        accept={ALLOWED_FILE_TYPES.join(",")}
      />
      <label
        className={classNames(
          "text-darkest border-secondary bg-primary hover:bg-tertiary rounded-lg border px-[18px] py-[6px] text-center text-sm !font-medium leading-5",
          {
            "pointer-events-none cursor-not-allowed": status === "uploading",
          },
        )}
        htmlFor="custom-icon-upload"
      >
        {UPLOAD_BUTTON_CTA[status]}
      </label>
      <div className="text-center">
        <Typography.LabelXs color="text-default">
          {t("emoji_picker_v3.custom_icon.file_upload.recommended_size")} <br />
          {t("emoji_picker_v3.custom_icon.file_upload.file_types")}
        </Typography.LabelXs>
      </div>
    </>
  );
};
