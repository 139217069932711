import { useEffect, useRef } from "react";
import classnames from "classnames";
import { ColorPicker, toColor, useColor } from "react-color-palette";
import { useClickAway } from "react-use";
import "./styles.scss";

const pickerWidth = 166;
const pickerHeight = 114;

interface HexColorPickerProps {
  disabled?: boolean;
  onChange: (value: string) => void;
  onOpenPicker: () => void;
  onClosePicker: () => void;
  show?: boolean;
  value: string;
}

export const HexColorPicker = ({
  onOpenPicker,
  onClosePicker,
  show = false,
  onChange,
  value,
  disabled = false,
}: HexColorPickerProps) => {
  const [currentColor, setCurrentColor] = useColor("hex", value);
  const popUpRef = useRef<HTMLDivElement>(null);

  // Update color preview when the user manually changes the color value
  useEffect(() => {
    if (value === currentColor.hex || show) return;
    const color = toColor("hex", value);
    setCurrentColor(color);
  }, [setCurrentColor, value, show, currentColor.hex]);

  useClickAway(popUpRef, onClosePicker);

  return (
    <div
      className={classnames("relative flex h-5 w-5 items-center", {
        "pointer-events-none": disabled,
      })}
    >
      <button
        type="button"
        className="h-5 w-5 cursor-pointer rounded ring-1 ring-current"
        style={{ backgroundColor: currentColor.hex }}
        onClick={onOpenPicker}
      />
      {show && (
        <div
          className="border-primary bg-primary absolute left-0 top-5 z-10 min-h-[160px] w-48 rounded-lg border p-3"
          ref={popUpRef}
          data-testid="color-picker-popup"
        >
          {/* TODO: This color picker is not keyboard accessible, we need to investigate alternatives */}
          <ColorPicker
            width={pickerWidth}
            height={pickerHeight}
            color={currentColor}
            onChange={color => {
              setCurrentColor(color);
            }}
            onChangeComplete={color => {
              if (color.hex !== value) {
                onChange(color.hex);
              }
            }}
            hideHSV
            hideHEX
            hideRGB
          />
        </div>
      )}
    </div>
  );
};
