import { Fragment, forwardRef, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Icon } from "@circle-react/components/shared/Icon";
import { dropdowListInOut } from "@circle-react-uikit/animations";
import "./styles.scss";

export const SimpleDropdown = forwardRef(
  (
    {
      value,
      fullWidth,
      showAllItems,
      menuItems,
      onChange,
      disabled,
      allowEmpty = false,
      placeholder,
      dataTestId,
      onBlur,
      name,
    },
    ref,
  ) => {
    const [buttonText, setButtonText] = useState();

    useEffect(() => {
      if (!menuItems || menuItems?.length === 0) return;
      let selected;

      if (!value && !allowEmpty) {
        selected = menuItems[0];
      } else if (value) {
        selected = menuItems.find(option => option.value === value);
      }
      setButtonText(selected?.label || placeholder);
    }, [value, menuItems, allowEmpty, placeholder]);

    return (
      <Menu
        as="div"
        className={classNames("react-dropdown", {
          "react-dropdown--fullWidth": fullWidth,
          "react-dropdown--showAllItems": showAllItems,
        })}
        data-testid={dataTestId}
      >
        {({ open }) => (
          <>
            <Menu.Button
              ref={ref}
              disabled={disabled}
              className={classNames(
                "form-control react-dropdown__button disabled:react-dropdown__button--disabled",
                {
                  "react-dropdown__button--disabled": disabled,
                  "text-default ring-1 ring-current": open,
                },
              )}
              data-testid="dropdown-button"
              onBlur={onBlur}
              name={name}
            >
              <span>{buttonText}</span>
              <Icon
                type="cheveron-down"
                className="react-dropdown__button-icon"
              />
            </Menu.Button>
            <Transition as={Fragment} show={open} {...dropdowListInOut}>
              <Menu.Items
                static
                className="react-dropdown__items !mt-1"
                data-testid="dropdown-items"
              >
                {menuItems.map(item => {
                  if (item.hidden) return;

                  return (
                    <Menu.Item key={item.value}>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={() => onChange(item.value)}
                          className={classNames(
                            "react-dropdown__item",
                            {
                              "react-dropdown__item--active": active,
                            },
                            item.className,
                          )}
                          data-testid="dropdown-option"
                        >
                          {item.label}
                        </button>
                      )}
                    </Menu.Item>
                  );
                })}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    );
  },
);

SimpleDropdown.displayName = "SimpleDropdown";
