import type { ReactNode } from "react";
import { createContext, createRef, useContext } from "react";
import type { PropsWithChildren } from "react";
import type { CustomEmoji, EmojiData } from ".";
import type { PopperProps } from "react-popper";
import { TAB_ITEMS } from "./constants";
import { useEmojiPicker } from "./hooks/useEmojiPicker";
import type { EmojiPickerState } from "./hooks/useEmojiPicker";

export interface EmojiPickerProviderValues {
  targetWrapperClassName?: string;
  popoverPanelClassName?: string;
  target?: ReactNode;
  shouldShowEmojiPreview?: boolean;
  shouldEmojiPickerRenderAsPortal?: boolean;
  value?: any;
  name?: string;
  onRemoveEmoji?: () => void;
  handleCustomIconUpload?: ({
    status,
    progress,
    response,
    isError,
  }: any) => void;
  onEmojiSelect?: (emojiData: EmojiData) => void;
  customIcons?: CustomEmoji[];
  customShapeIcons?: CustomEmoji[];
  shouldShowSkinTonePicker?: boolean;
  shouldCloseOnSelect?: boolean;
  portalTo?: HTMLElement | null;
  as?: keyof JSX.IntrinsicElements;
}

export interface EmojiPickerProviderProps {
  pickerAddOns: string[];
  placement?: PopperProps<any>["placement"];
  popperOptions?: PopperProps<any>["modifiers"];
  value: EmojiPickerProviderValues;
}

interface EmojiPickerContextValue
  extends EmojiPickerProviderValues,
    Omit<
      EmojiPickerState,
      | "setHasElementMutated"
      | "hasElementMutated"
      | "targetElement"
      | "popperElement"
    > {
  target?: ReactNode;
  targetWrapperClassName?: string;
  popoverPanelClassName?: string;
  shouldShowEmojiPreview?: boolean;
  shouldEmojiPickerRenderAsPortal?: boolean;
}

const EmojiPickerContext = createContext<EmojiPickerContextValue>({
  activeTab: TAB_ITEMS[0],
  setActiveTab: () => {},
  setPopperElement: () => {},
  setTargetElement: () => {},
  emojiPickerPopoverRef: createRef(),
  isEmojiPickerTabActive: false,
  isIconPickerTabActive: false,
  isShapePickerTabActive: false,
  isCustomPickerTabActive: false,
  shouldRenderEmojiMartPicker: false,
  styles: {},
  attributes: {},
  EMOJI_PICKER_ENABLED_TABS_MAPPER: {},
  isCustomFileUploadProcessing: false,
  setIsCustomFileUploadProcessing: () => {},
});
EmojiPickerContext.displayName = "EmojiPickerContext";

export const EmojiPickerProvider = ({
  pickerAddOns,
  placement,
  popperOptions,
  children,
  value,
}: PropsWithChildren<EmojiPickerProviderProps>) => {
  const {
    activeTab,
    setActiveTab,
    setPopperElement,
    setTargetElement,
    emojiPickerPopoverRef,
    isEmojiPickerTabActive,
    isIconPickerTabActive,
    isShapePickerTabActive,
    isCustomPickerTabActive,
    shouldRenderEmojiMartPicker,
    styles,
    attributes,
    EMOJI_PICKER_ENABLED_TABS_MAPPER,
    isCustomFileUploadProcessing,
    setIsCustomFileUploadProcessing,
  } = useEmojiPicker({ pickerAddOns, placement, popperOptions });

  return (
    <EmojiPickerContext.Provider
      value={{
        activeTab,
        setActiveTab,
        setPopperElement,
        setTargetElement,
        emojiPickerPopoverRef,
        isEmojiPickerTabActive,
        isIconPickerTabActive,
        isShapePickerTabActive,
        isCustomPickerTabActive,
        shouldRenderEmojiMartPicker,
        styles,
        attributes,
        EMOJI_PICKER_ENABLED_TABS_MAPPER,
        isCustomFileUploadProcessing,
        setIsCustomFileUploadProcessing,
        ...value,
      }}
    >
      {children}
    </EmojiPickerContext.Provider>
  );
};

export const useEmojiPickerContext = () => useContext(EmojiPickerContext);
