import type { ReactNode } from "react";
import { Typography } from "@circle-react/components/shared/uikit/Typography";

export interface HeaderProps {
  children: ReactNode;
}

export const Header = ({ children }: HeaderProps) => (
  <div className="flex items-center px-3 py-2">
    <Typography.LabelXsPlus color="text-lightest" weight="medium">
      {children}
    </Typography.LabelXsPlus>
  </div>
);
