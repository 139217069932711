import PropTypes from "prop-types";
import { last } from "lodash";
import {
  BACKSPACE_KEY_CODE,
  DELETE_KEY_CODE,
} from "@circle-react/components/constants";
import { Tag } from "@circle-react-uikit/Tag";
import { SearchInput } from "./SearchInput";

export const MultiSelectInput = props => {
  const { selectedItems, onDeselect, hideSelectedItems = false } = props;

  const mayBeDeselectLatestTag = event => {
    if (event.target.value.length) {
      return;
    }

    if ([DELETE_KEY_CODE, BACKSPACE_KEY_CODE].includes(event.keyCode)) {
      const lastItem = last(selectedItems);
      lastItem && onDeselect(lastItem);
    }
  };

  return (
    <>
      {!hideSelectedItems &&
        selectedItems.map(item => (
          <Tag
            key={item.value}
            label={item.text}
            onClose={event => {
              event.stopPropagation();
              event.preventDefault();
              onDeselect(item);
            }}
          />
        ))}
      <SearchInput {...props} onKeyDown={mayBeDeselectLatestTag} />
    </>
  );
};

MultiSelectInput.propTypes = {
  hideSelectedItems: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  onDeselect: PropTypes.func.isRequired,
};
