import { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const InputWrapper = forwardRef(
  ({ children, className, isError }, ref) => (
    <div
      ref={ref}
      className={classNames(
        "focus-within:border-light bg-primary text-dark relative block w-full appearance-none rounded-lg border transition-all",
        {
          "border-feedback-error": isError,
          "border-primary": !isError,
        },
        className,
      )}
    >
      {children}
    </div>
  ),
);
InputWrapper.displayName = "InputWrapper";

InputWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isError: PropTypes.bool,
};
