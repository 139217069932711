import { Controller, useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { SimpleDropdown } from "@circle-react/components/shared/uikit/SimpleDropdown";

export const DurationSelect = ({ name, disabled, fullWidth, dataTestId }) => {
  const { control } = useFormContext();
  const options = [
    {
      label: `15 ${t("minutes")}`,
      value: 900,
    },
    {
      label: `30 ${t("minutes")}`,
      value: 1800,
    },
    {
      label: `45 ${t("minutes")}`,
      value: 2700,
    },
    {
      label: `1 ${t("hour")}`,
      value: 3600,
    },
  ];

  for (let i = 1.5; i < 24; i = i + 0.5) {
    var element = {
      label: `${i} ${t("hours")}`,
      value: i * 3600,
    };
    options.push(element);
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <SimpleDropdown
          fullWidth={fullWidth}
          value={value}
          menuItems={options}
          onChange={onChange}
          disabled={disabled}
          dataTestId={dataTestId}
        />
      )}
    />
  );
};

DurationSelect.displayName = "Form.DurationSelect";
