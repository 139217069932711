import PropTypes from "prop-types";
import classNames from "classnames";

export const SearchInput = ({
  searchInputRef,
  onSearchChange,
  placeholder,
  searchValue,
  handleOpen,
  onKeyDown,
  disabled = false,
  autoFocus = false,
}) => (
  <input
    onFocus={handleOpen}
    ref={searchInputRef}
    value={searchValue}
    placeholder={placeholder}
    disabled={disabled}
    className={classNames(
      "search-input placeholder:text-light placeholder:opacity-100 disabled:cursor-not-allowed disabled:opacity-50",
      {
        "!bg-secondary cursor-not-allowed": disabled,
      },
    )}
    onChange={event => {
      event.stopPropagation();
      handleOpen();
      onSearchChange(event.target.value);
    }}
    autoCapitalize="false"
    autoComplete="false"
    onKeyDown={onKeyDown}
    autoFocus={autoFocus}
  />
);

SearchInput.propTypes = {
  searchValue: PropTypes.string.isRequired,
  handleOpen: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  placeholder: PropTypes.any,
  searchInputRef: PropTypes.object.isRequired,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};
