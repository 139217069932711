import type { ComponentPropsWithoutRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import type { FieldValues, Path } from "react-hook-form";
import { SliderWithInput } from "../../../SliderWithInput";

interface SliderControllerProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<
    ComponentPropsWithoutRef<typeof SliderWithInput>,
    "value" | "onChange"
  > {
  name: Path<TFieldValues>;
}

export const FormSliderWithInput = <
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  ...sliderProps
}: Readonly<SliderControllerProps<TFieldValues>>) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <SliderWithInput {...sliderProps} value={value} onChange={onChange} />
      )}
    />
  );
};
