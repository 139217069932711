import type { Modifier } from "react-popper";

const isHTMLElement = (element: unknown): element is HTMLElement =>
  element instanceof HTMLElement;

// https://popper.js.org/docs/v2/modifiers/community-modifiers/
export const popperSameWidthModifier: Modifier<"sameWidth"> = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    if (!isHTMLElement(state.elements.reference)) {
      return;
    }
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
};
