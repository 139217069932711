import { forwardRef } from "react";
import type { ComponentPropsWithRef, ReactNode } from "react";
import classNames from "classnames";
import { Typography } from "@circle-react-uikit/Typography";

export interface ModalTitleProps extends ComponentPropsWithRef<"div"> {
  children: ReactNode;
  className?: string;
  size?: "md" | "sm";
}

const { TitleMd, TitleSm } = Typography;

export const ModalTitle = forwardRef<HTMLDivElement, ModalTitleProps>(
  ({ children, className = "", size = "md", ...rest }, ref) => {
    if (!children) return null;

    return (
      <div
        ref={ref}
        className={classNames("w-full", className, {
          "px-8": className.includes("text-center"),
        })}
        data-testid="modal-title"
        {...rest}
      >
        {size === "md" && <TitleMd weight="font-bold">{children}</TitleMd>}
        {size === "sm" && <TitleSm weight="font-semibold">{children}</TitleSm>}
      </div>
    );
  },
);

ModalTitle.displayName = "ModalTitle";
