import PropTypes from "prop-types";
import classNames from "classnames";

const VERTICAL_SPACING = {
  normal: "normal",
  tight: "tight",
};
// From TailwindUI: https://tailwindui.com/components/application-ui/forms/radio-groups
export const Fieldset = ({
  legend = "",
  children,
  legendProps = {},
  divProps = {},
  verticalSpacing = VERTICAL_SPACING.normal,
  ...rest
}) => (
  <fieldset {...rest}>
    {legend && (
      <legend className="sr-only" {...legendProps}>
        {legend}
      </legend>
    )}
    <div
      className={classNames({
        "space-y-5": verticalSpacing == VERTICAL_SPACING.normal,
        "space-y-3": verticalSpacing == VERTICAL_SPACING.tight,
      })}
      {...divProps}
    >
      {children}
    </div>
  </fieldset>
);

Fieldset.propTypes = {
  legend: PropTypes.string,
  children: PropTypes.node,
  legendProps: PropTypes.object,
  divProps: PropTypes.object,
  verticalSpacing: PropTypes.oneOf([
    ...Object.values(VERTICAL_SPACING),
    "none",
  ]),
};
