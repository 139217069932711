import { forwardRef } from "react";
import type { ComponentPropsWithRef, ReactNode } from "react";
import classNames from "classnames";

export interface ModalHeaderProps extends ComponentPropsWithRef<"div"> {
  className?: string;
  children: ReactNode;
}

export const ModalHeader = forwardRef<HTMLDivElement, ModalHeaderProps>(
  ({ children, className = "", ...rest }, ref) => (
    <div
      ref={ref}
      className={classNames(
        "text-dark border-primary sticky flex items-center justify-between border-b px-5 py-4 md:border-none md:px-8 md:pb-2 md:pt-6",
        className,
      )}
      data-testid="modal-header"
      {...rest}
    >
      {children}
    </div>
  ),
);

ModalHeader.displayName = "ModalHeader";
