import { Suspense, useCallback } from "react";
import { noop } from "lodash";
import { useCurrentCommunityMember } from "@circle-react/contexts";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { useEmojiPickerContext } from "../../EmojiPickerContext";
import { SuspenseLoader } from "../../SuspenseLoader";
import {
  EMOJI_PICKER_CATEGORIES,
  ICON_PICKER_CATEGORIES,
  SHAPE_PICKER_CATEGORIES,
} from "./constants";
import { useEmojiMartData } from "./useEmojiMartData";
import { generateSelectedValue } from "./utils";

const Picker = lazyWithRetry(
  () => import(/* webpackChunkName: "Emoji_Mart_React" */ "@emoji-mart/react"),
);

export interface EmojiMartPickerProps {
  close: () => void;
}

export const EmojiMartPicker = ({ close }: EmojiMartPickerProps) => {
  const {
    isEmojiPickerTabActive,
    isIconPickerTabActive,
    isShapePickerTabActive,
    customIcons = [],
    customShapeIcons = [],
    value,
    name,
    shouldShowEmojiPreview,
    shouldShowSkinTonePicker,
    onEmojiSelect = noop,
    shouldCloseOnSelect,
  } = useEmojiPickerContext();

  const currentCommunityMember = useCurrentCommunityMember();

  const { data: emojiMartData, isLoading } = useEmojiMartData();

  const generateCategories = useCallback(() => {
    if (isEmojiPickerTabActive) return EMOJI_PICKER_CATEGORIES;
    if (isIconPickerTabActive) return ICON_PICKER_CATEGORIES;
    if (isShapePickerTabActive) return SHAPE_PICKER_CATEGORIES;
    return undefined;
  }, [isEmojiPickerTabActive, isIconPickerTabActive, isShapePickerTabActive]);

  const handleEmojiSelect = (emoji: any) => {
    onEmojiSelect(generateSelectedValue(emoji), name);
    if (shouldCloseOnSelect) close();
  };

  if (isLoading) return <SuspenseLoader />;

  const locale = currentCommunityMember?.locale || "en";

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <div
        data-emoji-tab-active={isEmojiPickerTabActive}
        data-shape-tab-active={isShapePickerTabActive}
        data-icon-tab-active={isIconPickerTabActive}
      >
        <Picker
          autoFocus
          locale={locale}
          dynamicWidth
          data={emojiMartData}
          custom={[...customIcons, ...customShapeIcons]}
          categories={generateCategories()}
          navPosition="none"
          previewPosition={shouldShowEmojiPreview ? "bottom" : "none"}
          skinTonePosition={
            isEmojiPickerTabActive && shouldShowSkinTonePicker
              ? "search"
              : "none"
          }
          emojiButtonRadius="8px"
          onEmojiSelect={handleEmojiSelect}
          previewEmoji={value?.id || "blue-circle"}
        />
      </div>
    </Suspense>
  );
};

export default EmojiMartPicker;
