import { forwardRef } from "react";
import { isFunction } from "lodash";
import { useFormContext } from "react-hook-form";
import type { RegisterOptions } from "react-hook-form";
import { mergeRefs } from "@circle-react/helpers/refHelpers";
import { Input } from "@circle-react-uikit/Input";
import type { InputOrArea, InputProps } from "@circle-react-uikit/Input";

export interface FormInputProps extends InputProps {
  name: string;
  rules?: RegisterOptions;
  transformValue?: (value: any) => any;
}

export const FormInput = forwardRef<InputOrArea, FormInputProps>(
  (
    {
      className = "form-control",
      hideBorder = false,
      name,
      noPadding = false,
      onBlur,
      onChange,
      prefix,
      rules = {},
      suffix,
      transformValue,
      variant = "text",
      required = false,
      ...rest
    },
    ref,
  ) => {
    const { register } = useFormContext();

    const {
      ref: formRef,
      onChange: formOnChange,
      onBlur: formOnBlur,
      name: formName,
    } = register(name, rules);

    const handleOnChange = (event: any) => {
      if (isFunction(transformValue)) {
        event.target.value = transformValue(event.target.value);
      }

      if (isFunction(onChange)) onChange(event);
      void formOnChange(event);
    };

    const handleOnBlur = (event: any) => {
      if (isFunction(onBlur)) onBlur(event);
      void formOnBlur(event);
    };

    const hasRequiredRule = Boolean(
      rules && typeof rules.required === "boolean" && rules.required,
    );

    return (
      <Input
        name={formName}
        className={className}
        hideBorder={hideBorder}
        noPadding={noPadding}
        prefix={prefix}
        suffix={suffix}
        variant={variant}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        ref={mergeRefs([ref, formRef])}
        required={required || hasRequiredRule}
        {...rest}
      />
    );
  },
);

FormInput.displayName = "Form.Input";
