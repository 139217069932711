import { Controller, useFormContext } from "react-hook-form";
import { DropZone } from "@circle-react/components/shared/DropZone";
import { ImagePreview } from "./ImagePreview";
import "./styles.scss";

export interface FormDropZoneProps {
  name: string;
  rules: any;
  accept: string;
  onRemove?: () => void;
}

export const FormDropZone = ({
  name,
  rules,
  accept,
  onRemove,
}: FormDropZoneProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }: any) => {
        const handleFileDrop = (files: any) => {
          onChange(files[0]);
        };

        const reset = () => onChange(null);

        if (value) {
          return (
            <ImagePreview value={value} onRemove={onRemove} reset={reset} />
          );
        }
        return <DropZone accept={accept} onFileDrop={handleFileDrop} />;
      }}
    />
  );
};

FormDropZone.displayName = "Form.DropZone";
