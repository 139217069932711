import { useMemo } from "react";

export const useFilteredItems = (
  data: any,
  filterBy: any,
  searchValue: any,
  shouldFilterSelectedItems: any,
  value: any,
) =>
  useMemo(() => {
    if (!filterBy && !shouldFilterSelectedItems) {
      return data;
    }

    return data.filter((item: any) => {
      const isValueAlreadySelected = value.includes(item.value);
      if (shouldFilterSelectedItems && isValueAlreadySelected) {
        return false;
      }

      if (filterBy) {
        return filterBy(item, searchValue);
      }

      return true;
    });
  }, [data, filterBy, searchValue, shouldFilterSelectedItems, value]);
