import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { EmojiPickerV3 } from "../../EmojiPickerV3";

export const FormEmojiPicker = ({ name, ...passThroughProps }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <EmojiPickerV3
          {...passThroughProps}
          value={value}
          onEmojiSelect={onChange}
        />
      )}
    />
  );
};

FormEmojiPicker.propTypes = {
  name: PropTypes.string.isRequired,
};

FormEmojiPicker.displayName = "Form.EmojiPickerV3";
