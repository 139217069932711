import { Controller, useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { ScoreElement } from "./ScoreElement";
import "./styles.scss";

const ScorePickerComponent = ({ onChange, value }) => (
  <div className="form-score-picker">
    <ScoreElement
      icon="😠"
      label={t("score_picker.awful")}
      onClick={() => onChange(1)}
      selected={value === 1}
    />
    <ScoreElement
      icon="🙁"
      label={t("score_picker.bad")}
      onClick={() => onChange(2)}
      selected={value === 2}
    />
    <ScoreElement
      icon="🙂"
      label={t("score_picker.okay")}
      onClick={() => onChange(3)}
      selected={value === 3}
    />
    <ScoreElement
      icon="😃"
      label={t("score_picker.good")}
      onClick={() => onChange(4)}
      selected={value === 4}
    />
    <ScoreElement
      icon="😍"
      label={t("score_picker.great")}
      onClick={() => onChange(5)}
      selected={value === 5}
    />
  </div>
);

export const ScorePicker = ({ name, rules, ...passThroughProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <ScorePickerComponent
          {...passThroughProps}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};

ScorePicker.displayName = "Form.ScorePicker";
