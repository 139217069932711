import { RadioGroupBox } from "./Box";
import type { RadioGroupBoxProps } from "./Box";
import { RadioGroupRadio } from "./Radio";
import type { RadioGroupRadioProps } from "./Radio";

interface BoxVariant extends RadioGroupBoxProps {
  variant: "box";
}

interface RadioVariant extends RadioGroupRadioProps {
  variant: "radio";
}

type RadioGroupProps = BoxVariant | RadioVariant;

export const RadioGroup = ({ variant = "box", ...rest }: RadioGroupProps) => {
  if (variant === "box") {
    return <RadioGroupBox {...rest} />;
  }

  if (variant === "radio") {
    return <RadioGroupRadio {...rest} />;
  }

  throw new Error("Unknown variant");
};

RadioGroup.displayName = "Form.RadioGroup";
