import { APPEARANCE } from "@/react/providers";

const constructCustomEmojiIcon = (name, src) => ({
  id: `icon-${name}`,
  name,
  short_names: [name],
  skins: [
    {
      src,
    },
  ],
});

const requireLightAppearanceContext = require.context(
  "@circle-assets/images/emoji-picker-v3/icons/light",
  true,
  /^\.\/.*\.png$/,
);

const requireDarkAppearanceContext = require.context(
  "@circle-assets/images/emoji-picker-v3/icons/dark",
  true,
  /^\.\/.*\.png$/,
);

const LightAppearanceIcons = requireLightAppearanceContext.keys().map(key => {
  const name = key.replace("./icon-", "").replace(".png", "");
  return constructCustomEmojiIcon(name, requireLightAppearanceContext(key));
});

const DarkAppearanceIcons = requireDarkAppearanceContext.keys().map(key => {
  const name = key.replace("./icon-", "").replace(".png", "");
  return constructCustomEmojiIcon(name, requireDarkAppearanceContext(key));
});

export const CUSTOM_ICON_LIGHT_DATA_SET = [
  {
    id: "icons",
    name: "icons",
    emojis: LightAppearanceIcons,
  },
];

export const CUSTOM_ICON_DARK_DATA_SET = [
  {
    id: "icons",
    name: "icons",
    emojis: DarkAppearanceIcons,
  },
];

export const customEmojiIconUrl = (identifier, appearance) => {
  let dataset = CUSTOM_ICON_LIGHT_DATA_SET;
  if (appearance === APPEARANCE.DARK) {
    dataset = CUSTOM_ICON_DARK_DATA_SET;
  }
  const emoji = dataset[0].emojis.find(emoji => emoji.id === identifier);

  return emoji?.skins?.[0]?.src;
};
