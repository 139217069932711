// https://github.com/react-hook-form/react-hook-form/discussions/1991
export const dirtyValues = (dirtyFields: any, allValues: any): any => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key: string) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ]),
  );
};
