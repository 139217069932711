export const PICKER_CSS_VARS = `
em-emoji-picker {
  --border-radius: 0;
  --shadow: 0 0 0 0 rgba(var(--color-black), 0);
  --font-family: var(--font-system-ui);
  --font-size: 11px;

  height: 360px;
  width: 100%;

  --em-rgb-input: var(--color-black);
  --em-rgb-background: var(--color-background-emoji-picker);

  --color-border: var(--color-border-primary);

  --rgb-color: var(--color-text-emoji);
  --rgb-background: var(--color-background-emoji-picker);
  --rgb-accent: var(--color-background-emoji-picker-hover);

  --preview-placeholder-size: 14px;
}
`;

export const PICKER_STYLES = ` 
  .search input[type="search"] {
    background-color: var(--color-background-primary) !important;
    border: 1px solid var(--color-border-primary);
    border-radius: 8px;
    padding: 12px 32px;
    color: var(--color-text-dark);
    font-size: 14px;
  }

  span.icon.loupe.flex {
    color: var(--color-text-light);
    font-size: 16px;
  }

  div.category {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.35px;
    color: var(--color-text-dark);
    font-family: var(--font-system-ui);
    padding-bottom: 14px;
  }
  div.search,
  .skin-tone-button{
    margin-bottom: 12px;
  }
`;

export const EMOJI_PICKER_CATEGORIES = [
  "people",
  "nature",
  "foods",
  "activity",
  "travel",
  "objects",
  "places",
  "symbols",
  "flags",
];

export const ICON_PICKER_CATEGORIES = ["icons"];

export const SHAPE_PICKER_CATEGORIES = [
  "circle",
  "square",
  "triangle",
  "star",
  "heart",
  "diamond",
];
