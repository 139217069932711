import { Dropdown } from ".";
import { uniqueId } from "lodash";
import { Icon } from "@circle-react-shared/Icon";
import { BadgeV2 } from "@circle-react-uikit/BadgeV2";
import { Typography } from "@circle-react-uikit/Typography";
import type { Option } from "./Option";
import { isSeparatorOption } from "./Option";

export interface IconWithBadgeListProps {
  options: Option[];
  linkType?: "button" | "submit" | "reset";
}

export const IconWithBadgeList = ({
  options,
  linkType,
}: IconWithBadgeListProps) => (
  <>
    {options.map(option => {
      if (isSeparatorOption(option)) {
        return <Dropdown.Separator key={uniqueId("separator-")} />;
      }

      const {
        value,
        label,
        icon,
        iconSize,
        pathTo,
        badge,
        badgeVariant,
        onClick,
        dataTestId,
        isActive,
      } = option;

      return (
        <Dropdown.ItemWithLink
          key={value}
          to={pathTo}
          onClick={onClick}
          linkType={linkType}
          active={isActive}
        >
          <Dropdown.BadgeContainer>
            <Dropdown.IconContainer>
              {icon && (
                <span className="flex h-5 w-5 self-start">
                  <Icon type={icon} size={iconSize ?? 16} />
                </span>
              )}
              <Typography.LabelXsPlus weight="medium" data-testid={dataTestId}>
                {label}
              </Typography.LabelXsPlus>
            </Dropdown.IconContainer>
            {badge && (
              <div className="flex">
                {badgeVariant !== "plain" ? (
                  <BadgeV2 inverted square label={badge} />
                ) : (
                  <Typography.LabelXsPlus
                    weight="medium"
                    data-testid={dataTestId}
                  >
                    {badge}
                  </Typography.LabelXsPlus>
                )}
              </div>
            )}
          </Dropdown.BadgeContainer>
        </Dropdown.ItemWithLink>
      );
    })}
  </>
);
