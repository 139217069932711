import noop from "lodash/noop";
import type { PopperProps } from "react-popper";
import { EmojiPickerProvider } from "./EmojiPickerContext";
import type { EmojiPickerProviderValues } from "./EmojiPickerContext";
import { EmojiPopOver } from "./EmojiPopover";
import { CUSTOM_ICON_LIGHT_DATA_SET, CUSTOM_SHAPE_DATA_SET } from "./data";

interface EmojiSkin {
  src: string;
}

export interface Emoji {
  id: string;
  name: string;
  short_names: string[];
  skins: EmojiSkin[];
}

export interface CustomEmoji {
  name: string;
  id: string;
  emojis: Emoji[];
}

export interface EmojiData {
  emoji: string;
  emoji_type: string;
}

type EmojiPickerAddOns = "icons" | "shapes" | "custom";

interface EmojiPickerV3Props extends EmojiPickerProviderValues {
  popperOptions?: PopperProps<any>["modifiers"];
  placement?: PopperProps<any>["placement"];
  pickerAddOns?: EmojiPickerAddOns[];
}

export const EmojiPickerV3 = ({
  as = "button",
  target,
  popperOptions,
  value,
  name,
  onRemoveEmoji,
  targetWrapperClassName = "",
  popoverPanelClassName = "",
  placement = "bottom-start",
  pickerAddOns = [],
  handleCustomIconUpload = noop,
  onEmojiSelect = noop,
  customIcons = CUSTOM_ICON_LIGHT_DATA_SET,
  customShapeIcons = CUSTOM_SHAPE_DATA_SET,
  shouldShowEmojiPreview = false,
  shouldShowSkinTonePicker = true,
  shouldEmojiPickerRenderAsPortal = true,
  shouldCloseOnSelect = true,
  portalTo,
}: EmojiPickerV3Props) => (
  <EmojiPickerProvider
    pickerAddOns={pickerAddOns}
    placement={placement}
    popperOptions={popperOptions}
    value={{
      as,
      targetWrapperClassName,
      popoverPanelClassName,
      shouldShowEmojiPreview,
      target,
      shouldEmojiPickerRenderAsPortal,
      value,
      name,
      onRemoveEmoji,
      handleCustomIconUpload,
      onEmojiSelect,
      customIcons,
      customShapeIcons,
      shouldShowSkinTonePicker,
      shouldCloseOnSelect,
      portalTo,
    }}
  >
    <EmojiPopOver />
  </EmojiPickerProvider>
);
