import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { CommunityMemberSelect } from "@circle-react-uikit/CommunityMemberSelect";

export const FormCommunityMemberSelect = ({ name, ...passThroughProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => (
        <CommunityMemberSelect
          {...passThroughProps}
          name={name}
          onChange={item => onChange(item.value)}
          value={value}
        />
      )}
    />
  );
};

FormCommunityMemberSelect.propTypes = {
  name: PropTypes.string.isRequired,
};

FormCommunityMemberSelect.displayName = "Form.CommunityMemberSelect";
