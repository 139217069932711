import { useState } from "react";
import classnames from "classnames";
import { toColor } from "react-color-palette";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { HexColorPicker } from "@circle-react-uikit/HexColorPicker";
import { FormInput } from "../FormInput";

export const isHexColor = (color = "") =>
  /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);

export interface FormColorPickerProps {
  name: string;
  disabled?: boolean;
}

export const FormColorPicker = ({
  name,
  disabled = false,
  ...rest
}: FormColorPickerProps) => {
  const { watch, setValue } = useFormContext();
  const [shouldShow, setShouldShow] = useState(false);
  const colorValue: string = watch(name);

  return (
    <FormInput
      {...rest}
      disabled={disabled}
      variant="text"
      name={name}
      maxLength={7}
      rules={{
        validate: (value: string) => {
          if (!isHexColor(value)) {
            return t("invalid_color");
          }
          return true;
        },
      }}
      onBlur={() => {
        const color = toColor("hex", colorValue);
        setValue(name, color.hex, { shouldDirty: true, shouldValidate: true });
      }}
      prefix={
        <div
          className={classnames("py-3 pl-4", {
            "cursor-not-allowed": disabled,
          })}
        >
          <HexColorPicker
            value={colorValue}
            onChange={value =>
              !disabled &&
              setValue(name, value, { shouldDirty: true, shouldValidate: true })
            }
            onOpenPicker={() => !disabled && setShouldShow(true)}
            onClosePicker={() => !disabled && setShouldShow(false)}
            show={shouldShow}
            disabled={disabled}
          />
        </div>
      }
      className="form-control !border-l-0 uppercase"
    />
  );
};

FormColorPicker.displayName = "Form.ColorPicker";
