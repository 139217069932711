import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import type { RegisterOptions } from "react-hook-form";

export interface Option {
  label: string;
  value: string | number;
}

export interface FormSelectNativeProps
  extends ComponentPropsWithoutRef<"select"> {
  name: string;
  rules?: RegisterOptions;
  prompt?: string;
  isPromptDisabled?: boolean;
  options: Array<Option> | ReadonlyArray<Option>;
  className?: string;
}

export const FormSelectNative = ({
  name,
  rules = {},
  options,
  className = "form-control",
  prompt = "",
  isPromptDisabled = true,
  ...rest
}: FormSelectNativeProps) => {
  const { register } = useFormContext();

  return (
    <select
      {...rest}
      {...register(name, rules)}
      className={classNames(
        "disabled:bg-secondary focus:!outline-secondary focus:!outline-1 focus:!outline-offset-0 disabled:cursor-not-allowed",
        className,
      )}
      name={name}
    >
      {prompt && (
        <option value="" disabled={isPromptDisabled}>
          {prompt}
        </option>
      )}
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};

FormSelectNative.displayName = "Form.SelectNative";
