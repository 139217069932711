import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { Select } from "@circle-react-uikit/Select";

export const FormSelect = ({ name, ...passThroughProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => (
        <Select
          {...passThroughProps}
          name={name}
          onChange={({ value }) => onChange(value)}
          value={value}
        />
      )}
    />
  );
};

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
};

FormSelect.displayName = "Form.Select";
