import { useEffect, useState } from "react";
import { findIndex } from "lodash";
import { useDimensions } from "@circle-react/hooks/utils/useDimensions";
import type { Option } from "../types";

const computeArrowLocation = (
  index: number,
  length: number,
  totalWidth: number,
) => {
  /* Find width of one option */
  const optionWidth = totalWidth / length;
  /* Move the arrow to the center of the selected option */
  let marginLeft = optionWidth / 2 + optionWidth * index;
  if (index + 1 !== length) {
    /* Subtract gap between the options */
    marginLeft -= 6;
  }
  return marginLeft;
};

export interface InfoBoxProps {
  value: string;
  options: Option[];
  showArrow?: boolean;
}

export const InfoBox = ({
  value,
  options = [],
  showArrow = false,
}: InfoBoxProps) => {
  const [arrowLocation, setArrowLocation] = useState(0);
  const [measurer, { width: totalWidth }] = useDimensions<HTMLDivElement>();

  const position = findIndex(options, { value });
  const selectedOption = options[position] || {};

  useEffect(() => {
    setArrowLocation(
      computeArrowLocation(position, options.length, totalWidth),
    );
  }, [totalWidth, value]);

  const hasInfo = Boolean(selectedOption.info);

  if (!hasInfo) {
    return null;
  }

  return (
    <div className="infobox" ref={measurer}>
      {showArrow && (
        <div style={{ marginLeft: arrowLocation }} className="infobox__arrow" />
      )}
      <div className="infobox__content">
        {selectedOption.info}
        {selectedOption.infoSubText && (
          <span className="infobox__content-subtext">
            {selectedOption.infoSubText}
          </span>
        )}
      </div>
    </div>
  );
};
