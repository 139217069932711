import type { MouseEvent, ReactNode } from "react";
import { forwardRef } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";

const getIconType = ({ showDropdown, useEditIcon, value }: any) => {
  if (showDropdown) {
    return "close";
  }
  if (useEditIcon) {
    return value.length ? "pencil" : null;
  }
  return "cheveron-down";
};

export interface InputContainerProps {
  toggleDropdown: (event: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  useEditIcon?: boolean;
  showDropdown: boolean;
  value: string;
  disabled?: boolean;
  hideIcon?: boolean;
  showSearchIcon?: boolean;
  inputContainerClassName?: string;
}

export const InputContainer = forwardRef<HTMLDivElement, InputContainerProps>(
  (
    {
      toggleDropdown,
      children,
      useEditIcon,
      showDropdown,
      value,
      disabled = false,
      hideIcon = false,
      showSearchIcon = false,
      inputContainerClassName,
    },
    ref,
  ) => {
    const iconType = getIconType({ showDropdown, useEditIcon, value });
    return (
      <div
        ref={ref}
        className={classNames(
          `form-control bg-primary !border-primary ${inputContainerClassName}`,
          {
            "form-control--disabled": disabled,
            "focus-within:!border-light": !disabled,
          },
        )}
      >
        {showSearchIcon && (
          <span className="flex h-4 w-4">
            <Icon type="16-search" size={16} className="text-default" />
          </span>
        )}
        <div className="select-inputs-container">{children}</div>
        <button
          type="button"
          aria-label={iconType ?? t("options_dropdown")}
          className="icon-container"
          onClick={event => {
            event.stopPropagation();
            toggleDropdown(event);
          }}
        >
          {!hideIcon && iconType && (
            <Icon type={iconType} className="text-dark" />
          )}
        </button>
      </div>
    );
  },
);
InputContainer.displayName = "InputContainer";
