import { Fragment } from "react";
import type {
  CSSProperties,
  ComponentPropsWithoutRef,
  ReactNode,
  RefCallback,
  RefObject,
} from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { createPortal } from "react-dom";
import { Option, isSeparatorOption } from "./Option";
import type { Option as OptionType } from "./Option";
import { DROPDOWN_SIZE, defaultAnimations } from "./constants";
import type { DropdownAnimation, DropdownSize } from "./constants";

export type Options = OptionType[];

export interface MenuItemsProps {
  animations?: DropdownAnimation;
  appendTo?: Element;
  children?: ReactNode;
  hasOptions: boolean;
  isDark?: boolean;
  labelWeight?: string;
  linkType?: "button" | "submit" | "reset";
  menuWrapperClassName?: string;
  onChange?: (value: string) => void;
  open: boolean;
  options?: Options;
  popperAttributes?: ComponentPropsWithoutRef<"div">;
  popperElRef: RefObject<HTMLDivElement>;
  popperStyles?: CSSProperties;
  setPopperElement: RefCallback<HTMLDivElement>;
  shouldAddDivider?: boolean;
  size: DropdownSize;
  transitionWrapperClassName?: string;
  menuItemsClassName?: string;
}

export const MenuItems = ({
  open,
  size,
  onChange,
  options,
  hasOptions,
  menuWrapperClassName,
  transitionWrapperClassName,
  menuItemsClassName,
  shouldAddDivider,
  popperElRef,
  popperAttributes,
  popperStyles,
  appendTo,
  setPopperElement,
  children,
  linkType,
  labelWeight,
  isDark,
  animations: animationsProp,
}: MenuItemsProps) => {
  const shouldRenderPortal = !!appendTo;
  const animations = animationsProp || defaultAnimations;

  const component = (
    <div
      className={classNames(
        "z-10",
        {
          "w-full": size === DROPDOWN_SIZE.FULL,
        },
        transitionWrapperClassName,
      )}
      ref={popperElRef}
      style={popperStyles}
      {...popperAttributes}
    >
      <Transition
        as={Fragment}
        show={open}
        beforeEnter={() => setPopperElement(popperElRef?.current)}
        afterLeave={() => setPopperElement(null)}
        {...animations}
      >
        <div
          className={classNames(
            "mt-1",
            { "px-4": size === DROPDOWN_SIZE.FULL },
            menuWrapperClassName,
          )}
        >
          <Menu.Items
            static
            className={classNames(
              "overflow-hidden rounded-2xl border p-4 shadow-xl outline-none focus:outline-none",
              menuItemsClassName,
              isDark
                ? "border-[#34373D] bg-[#1F2123]"
                : "bg-primary border-primary",
              {
                "divide-primary divide-y": shouldAddDivider,
                "w-32": size === DROPDOWN_SIZE.SMALL,
                "w-56": size === DROPDOWN_SIZE.MEDIUM,
                "w-[17rem]": size === DROPDOWN_SIZE.LARGE,
                "w-96": size === DROPDOWN_SIZE.EXTRA_LARGE,
                "w-full": size === DROPDOWN_SIZE.FULL,
              },
            )}
            data-testid="dropdown-menu-items"
          >
            {options &&
              hasOptions &&
              options.map(option => (
                <Option
                  key={`${option.value}`}
                  option={option}
                  onChange={onChange}
                  linkType={linkType}
                  labelWeight={labelWeight}
                  isDark={isDark}
                  {...(!isSeparatorOption(option) && {
                    isActive: option.isActive,
                  })}
                />
              ))}
            {!hasOptions && children}
          </Menu.Items>
        </div>
      </Transition>
    </div>
  );

  return shouldRenderPortal ? (
    <>{createPortal(component, appendTo)}</>
  ) : (
    component
  );
};
