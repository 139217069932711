import PropTypes from "prop-types";

export const ValueAsHiddenFields = ({ multiple, value, name }) => {
  // We set this for uncontrolled inputs so that the value is submitted as part of form.
  if (!multiple) {
    return <input name={name} type="hidden" value={value[0]} />;
  }

  if (!value.length) {
    return <input name={`${name}[]`} type="hidden" value="" />;
  }

  return value.map(v => (
    <input key={v} name={`${name}[]`} type="hidden" value={v} />
  ));
};

ValueAsHiddenFields.propTypes = {
  multiple: PropTypes.bool,
  value: PropTypes.array,
  name: PropTypes.string,
};
