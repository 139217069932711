import { useState } from "react";
import { compact, flatten } from "lodash";

const UNCONTROLLED = "uncontrolled";
const CONTROLLED = "controlled";

export const useInputController = props => {
  let mode = UNCONTROLLED;
  // eslint-disable-next-line prefer-const -- Disabled to add linter rule to CI, PR #7718
  let [value, setValue] = useState(props.defaultValue);

  let onChange = ({ value: newValue }) => {
    setValue(newValue);
  };
  const isUncontrolledInput = () => mode === UNCONTROLLED;

  if (
    Object.prototype.hasOwnProperty.call(props, "onChange") &&
    Object.prototype.hasOwnProperty.call(props, "value")
  ) {
    mode = CONTROLLED;
    value = props.value;
    onChange = props.onChange;
  }
  value = compact(flatten([value]));

  return {
    value,
    onChange,
    isUncontrolledInput,
  };
};
