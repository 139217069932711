import { forwardRef } from "react";
import type { ComponentPropsWithRef } from "react";
import classNames from "classnames";

export type ModalBodyProps = ComponentPropsWithRef<"div">;

export const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>(
  ({ children, className = "", ...rest }, ref) => (
    <div
      ref={ref}
      className={classNames(
        "text-dark h-full overflow-y-auto px-5 py-2 md:px-8",
        className,
      )}
      data-testid="modal-body"
      {...rest}
    >
      {children}
    </div>
  ),
);

ModalBody.displayName = "ModalBody";
