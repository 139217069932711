import { forwardRef } from "react";
import type { ComponentPropsWithRef, ReactNode } from "react";
import classNames from "classnames";

export interface ModalHeaderActionsProps extends ComponentPropsWithRef<"div"> {
  children: ReactNode;
  className?: string;
}

export const ModalHeaderActions = forwardRef<
  HTMLDivElement,
  ModalHeaderActionsProps
>(({ children, className = "", ...rest }, ref) => (
  <div
    ref={ref}
    className={classNames("inline-flex gap-1 self-start", className)}
    data-testid="modal-header-actions"
    {...rest}
  >
    {children}
  </div>
));

ModalHeaderActions.displayName = "ModalHeaderActions";
