import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";
import { noop } from "lodash";

export interface ModalDataContext {
  contentPortalElement: HTMLElement | null;
  contentPortalId: string;
  isInsideModal: boolean;
  isOpen: boolean;
  onClose: () => void;
  setContentPortalElement: (element: HTMLElement | null) => void;
  shouldShowCloseButton: boolean;
}

const ModalData = createContext<ModalDataContext>({
  contentPortalElement: null,
  contentPortalId: "",
  isInsideModal: false,
  isOpen: false,
  onClose: noop,
  setContentPortalElement: noop,
  shouldShowCloseButton: false,
});

ModalData.displayName = "ModalData";

export const useModalData = () => useContext(ModalData);

export interface ModalDataProps {
  children: ReactNode;
  contentPortalId?: string;
  isInsideModal?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  shouldShowCloseButton?: boolean;
}

export const ModalDataProvider = ({
  children,
  contentPortalId = "",
  isInsideModal = false,
  isOpen = false,
  onClose = noop,
  shouldShowCloseButton = false,
}: ModalDataProps) => {
  const [contentPortalElement, setContentPortalElement] =
    useState<HTMLElement | null>(null);

  return (
    <ModalData.Provider
      value={{
        contentPortalId,
        isInsideModal,
        contentPortalElement,
        setContentPortalElement,
        onClose,
        shouldShowCloseButton,
        isOpen,
      }}
    >
      {children}
    </ModalData.Provider>
  );
};
