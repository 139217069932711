import type { ReactNode } from "react";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import type { RegisterOptions } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import { Fieldset } from "@circle-react-uikit/Fieldset";
import { Radio } from "@circle-react-uikit/Radio";
import type { Option } from "../types";

interface RenderOptionProps extends Option {
  name: string;
}

export interface RadioGroupRadioProps {
  name: string;
  options: Option[];
  rules?: RegisterOptions;
  verticalSpacing?: string;
  disabled?: boolean;
  renderOption?: (props: RenderOptionProps) => ReactNode;
}

export const RadioGroupRadio = ({
  name,
  rules = {},
  options,
  verticalSpacing = "tight",
  renderOption,
  disabled = false,
}: RadioGroupRadioProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <RadioGroup
          value={value}
          onChange={onChange}
          as={Fieldset}
          verticalSpacing={verticalSpacing}
        >
          {options.map(({ label, value, description }) =>
            renderOption ? (
              renderOption({ name, label, value, description })
            ) : (
              <RadioGroup.Option
                key={value}
                value={value}
                disabled={disabled}
                className={classNames({
                  "pointer-events-none": disabled,
                })}
              >
                {({ checked }) => (
                  <Radio
                    id={`${name}-radio-${value}`}
                    label={label}
                    description={description}
                    checked={checked}
                  />
                )}
              </RadioGroup.Option>
            ),
          )}
        </RadioGroup>
      )}
    />
  );
};
