import { forwardRef } from "react";
import type { ComponentPropsWithoutRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import {
  defaultOverlayEnterAnimation,
  defaultOverlayLeaveAnimation,
} from "@circle-react-uikit/animations";
import { useModalData } from "./ModalDataProvider";
import { useCurrentModalLevel } from "./ModalLevelContext";
import { useModalNesting } from "./ModalNestingContext";

export interface ModalOverlayProps extends ComponentPropsWithoutRef<"div"> {
  className?: string;
  enterAnimationProps?: {
    enter: string;
    enterFrom: string;
    enterTo: string;
  };
  leaveAnimationProps?: {
    leave: string;
    leaveFrom: string;
    leaveTo: string;
  };
}

export const ModalOverlay = forwardRef<HTMLDivElement, ModalOverlayProps>(
  (
    {
      className = "",
      enterAnimationProps = defaultOverlayEnterAnimation,
      leaveAnimationProps = defaultOverlayLeaveAnimation,
      ...rest
    },
    ref,
  ) => {
    const { totalNesting, hadNesting } = useModalNesting();
    const currentLevel = useCurrentModalLevel();
    const { onClose } = useModalData();

    const isFirstModal = currentLevel === 1;
    const isLastModal = currentLevel > totalNesting;
    const hasNesting = totalNesting > 0;

    const overlayClassName = classNames(
      "fixed inset-0 bg-modal-overlay",
      className,
    );

    if (isFirstModal && !hasNesting && !hadNesting) {
      return (
        <Transition.Child
          as={Dialog.Overlay}
          // @ts-expect-error - Transition.Child interface does not work correctly with `as={Dialog.Overlay}`
          className={overlayClassName}
          ref={ref}
          data-testid="modal-overlay"
          onClick={onClose}
          {...rest}
          {...enterAnimationProps}
          {...leaveAnimationProps}
        />
      );
    }

    if (isFirstModal && !hasNesting && hadNesting) {
      return (
        <Transition.Child
          as={Dialog.Overlay}
          // @ts-expect-error - Transition.Child interface does not work correctly with `as={Dialog.Overlay}`
          className={overlayClassName}
          ref={ref}
          data-testid="modal-overlay"
          onClick={onClose}
          {...rest}
          {...leaveAnimationProps}
        />
      );
    }

    if (isLastModal && hasNesting) {
      return (
        <Dialog.Overlay
          className={overlayClassName}
          ref={ref}
          data-testid="modal-overlay"
          onClick={onClose}
          {...rest}
        />
      );
    }

    return null;
  },
);

ModalOverlay.displayName = "ModalOverlay";
