import PropTypes from "prop-types";
import { Combobox as HeadlessUICombobox } from "@headlessui/react";
import classNames from "classnames";

export const Combobox = ({ children, value, onChange, className, ...rest }) => (
  <div className={classNames("relative", className)}>
    <HeadlessUICombobox value={value} onChange={onChange} {...rest}>
      {children}
    </HeadlessUICombobox>
  </div>
);

Combobox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  value: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
