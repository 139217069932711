import PropTypes from "prop-types";
import classnames from "classnames";
import { isFunction } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { Icon } from "@circle-react/components/shared/Icon";
import { If } from "@circle-react/components/shared/If";

export const SearchInput = ({
  name,
  placeholder = "Search",
  hideIcon,
  hideResetButton,
  onReset,
  fullWidth = false,
}) => {
  const { control } = useFormContext();

  const inputClass = classnames(
    "bg-primary text-dark placeholder:text-light placeholder:opacity-100",
    {
      "w-full": fullWidth,
    },
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const reset = () => {
          field.onChange("");
          if (isFunction(onReset)) {
            onReset();
          }
        };
        return (
          <>
            <input
              className={inputClass}
              type="textbox"
              {...field}
              placeholder={placeholder}
            />
            <If condition={!!field.value && !hideResetButton}>
              <Icon type="close" className="cursor-pointer" onClick={reset} />
            </If>
            <If condition={!field.value && !hideIcon}>
              <Icon type="search" />
            </If>
          </>
        );
      }}
    />
  );
};

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  hideIcon: PropTypes.bool,
  hideResetButton: PropTypes.bool,
  onReset: PropTypes.func,
  fullWidth: PropTypes.bool,
};

SearchInput.displayName = "Form.SearchInput";
