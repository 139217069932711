import type { ComponentPropsWithRef, Ref } from "react";
import { forwardRef } from "react";
import { t } from "@/i18n-js/instance";
import type { IconType } from "@circle-react/components/shared/Icon/IconNames";
import { IconButton } from "@circle-react-uikit/HeaderV3/IconButton";

type ModalBackButtonProps = Omit<
  ComponentPropsWithRef<"button">,
  "onClick" | "name"
> & {
  onClick: () => void;
  name?: IconType;
};

export const ModalBackButton = forwardRef<
  HTMLButtonElement,
  ModalBackButtonProps
>(
  (
    { onClick, className, disabled, name = "20-back", ...rest },
    ref: Ref<HTMLButtonElement>,
  ) => (
    <IconButton
      ref={ref}
      name={name}
      buttonSize={28}
      iconSize={20}
      variant="primary"
      iconClassName="!text-dark"
      type="button"
      onClick={onClick}
      data-testid="modal-back-button"
      ariaLabel={t("back")}
      className={className}
      disabled={disabled}
      {...rest}
    />
  ),
);

ModalBackButton.displayName = "ModalBackButton";
