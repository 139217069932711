import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Combobox } from "@circle-react-uikit/Combobox";
import { Typography } from "@circle-react-uikit/Typography";
import { popperSameWidthModifier } from "../Dropdown/utils";

const SearchableSelect = ({
  appendTo = null,
  children,
  displayValue = option => option?.label || "",
  isError = false,
  label = "",
  onChange = noop,
  onClose = noop,
  onFilter = noop,
  placeholder = t("select_an_option"),
  popperOptions = {},
  value = null,
  tooltipText = null,
  ...rest
}) => {
  const buttonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, forceUpdate } = usePopper(
    targetElement,
    popperElement,
    {
      placement: "bottom",
      strategy: "fixed",
      modifiers: [popperSameWidthModifier],
      ...popperOptions,
    },
  );

  const shouldUsePortal = Boolean(appendTo);

  const renderList = (
    <div
      className="z-10"
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      <Combobox.Options onClose={onClose}>
        <Combobox.OptionsScrollableContainer>
          {children}
        </Combobox.OptionsScrollableContainer>
      </Combobox.Options>
    </div>
  );

  return (
    <Combobox {...rest} value={value} onChange={onChange}>
      {({ open }) => {
        if (open !== isOpen) {
          setIsOpen(open);
          forceUpdate?.();
        }

        return (
          <>
            {label && (
              <Combobox.Label className="mb-2">
                <Typography.LabelSm weight="semibold">
                  {label}
                </Typography.LabelSm>
                {tooltipText && (
                  <span className="ml-2 inline-flex">
                    <TippyV2 content={tooltipText}>
                      <Icon type="question-mark" className="icon icon--xs" />
                    </TippyV2>
                  </span>
                )}
              </Combobox.Label>
            )}
            <Combobox.InputWrapper ref={setTargetElement} isError={isError}>
              <Combobox.Input
                displayValue={displayValue}
                onChange={event => onFilter(event.target.value)}
                placeholder={placeholder}
                onFocus={() => {
                  if (open) return;
                  buttonRef.current?.click();
                }}
                onClick={() => {
                  if (open) return;
                  buttonRef.current?.click();
                }}
              />
              <Combobox.ChevronDownButton ref={buttonRef} />
            </Combobox.InputWrapper>
            {!shouldUsePortal && renderList}
            {shouldUsePortal && createPortal(renderList, appendTo)}
          </>
        );
      }}
    </Combobox>
  );
};

SearchableSelect.propTypes = {
  children: PropTypes.node,
  displayValue: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  label: PropTypes.string,
  popperOptions: PropTypes.object,
  isError: PropTypes.bool,
};

SearchableSelect.EmptyOption = Combobox.EmptyOption;
SearchableSelect.Option = Combobox.Option;

export { SearchableSelect };
