import { useEffect, useMemo, useState } from "react";
import { compact, flatten } from "lodash";
import { t } from "@/i18n-js/instance";
import { useCommunityMemberSearch } from "@circle-react/hooks/useCommunityMemberSearch";
import type { CommunityMember } from "@circle-react/types";
import { Loader } from "@circle-react-uikit/Loader";
import { Select } from "@circle-react-uikit/Select";
import { MemberListItem } from "./MemberListItem";
import { formatCommunityMemberData, getAddableOption } from "./utils";

export const EMAIL_REGEX = /\S+@\S+\.\S+/;

interface CommunityMemberSelectProps
  extends React.ComponentProps<typeof Select> {
  valueField?: "id" | "user_id";
  addable?: boolean;
  placeholder?: string;
  excludeAdmins?: boolean;
  includeInactive?: boolean;
  filters?: Record<string, any>;
  shouldFilterSelectedItems?: boolean;
  getCommunityMemberText?: (communityMember: CommunityMember) => string;
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
}

const i18nRoot = "community_member_select";

export const CommunityMemberSelect = ({
  valueField = "id",
  addable,
  placeholder,
  excludeAdmins = false,
  includeInactive = false,
  filters = {},
  shouldFilterSelectedItems = false,
  getCommunityMemberText = communityMember => `${communityMember.name}`,
  ...passThroughProps
}: CommunityMemberSelectProps) => {
  const { searchCommunityMembers, communityMembers, resetCommunityMembers } =
    useCommunityMemberSearch();

  const { value, defaultValue } = passThroughProps;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const preloadValues = compact(flatten([defaultValue, value]));
    if (preloadValues.length) {
      searchCommunityMembers({
        filters: {
          [valueField]: preloadValues,
          exclude_admins: excludeAdmins,
          include_inactive: includeInactive,
          ...filters,
        },
      })
        .then(() => setIsLoading(false))
        .catch(error => {
          console.error(error);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const data = useMemo(
    () =>
      formatCommunityMemberData(
        communityMembers,
        valueField,
        getCommunityMemberText,
      ).map(({ value, text, member }) => ({
        value,
        text,
        listItemText: <MemberListItem member={member} />,
      })),
    [communityMembers, valueField, getCommunityMemberText],
  );

  if (isLoading) {
    return <Loader />;
  }

  const onSearch = async (query: any) => {
    if (!query) {
      resetCommunityMembers();
      return;
    }
    return searchCommunityMembers({
      filters: {
        exclude_admins: excludeAdmins,
        include_inactive: includeInactive,
        ...filters,
      },
      per_page: 10,
      query,
    });
  };

  const notFoundContent = addable
    ? t([i18nRoot, "no_matches_found_try_using_email"])
    : t([i18nRoot, "no_matches_found"]);

  const placeholderWithFallback =
    placeholder ?? t([i18nRoot, "start_typing_a_name"]);

  return (
    <Select
      {...passThroughProps}
      notFoundContent={notFoundContent}
      addable={addable ? getAddableOption : undefined}
      data={data}
      onSearch={onSearch}
      minSearchValueLength={1}
      placeholder={placeholderWithFallback}
      shouldFilterSelectedItems={shouldFilterSelectedItems}
      popperOptions={{
        modifiers: [{ name: "offset", options: { offset: [0, 2] } }],
      }}
    />
  );
};
