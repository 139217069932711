import { Combobox as HeadlessUICombobox } from "@headlessui/react";
import { ChevronDownButton } from "./ChevronDownButton";
import { Combobox as Component } from "./Combobox";
import { EmptyOption } from "./EmptyOption";
import { Input } from "./Input";
import { InputWrapper } from "./InputWrapper";
import { Option } from "./Option";
import { Options } from "./Options";
import { OptionsScrollableContainer } from "./OptionsScrollableContainer";

const Combobox = Component;
Combobox.ChevronDownButton = ChevronDownButton;
Combobox.EmptyOption = EmptyOption;
Combobox.Input = Input;
Combobox.InputWrapper = InputWrapper;
Combobox.Options = Options;
Combobox.OptionsScrollableContainer = OptionsScrollableContainer;
Combobox.Option = Option;
Combobox.Button = HeadlessUICombobox.Button;
Combobox.Label = HeadlessUICombobox.Label;

export { Combobox };
