import { PICKER_CSS_VARS, PICKER_STYLES } from "./constants";

// This method is to update the CSS variables in the shadow root of the emoji picker
export const updateShadowRootCssVars = () => {
  const shadow: HTMLStyleElement = document.createElement("style");
  shadow.innerHTML = PICKER_CSS_VARS;
  document.head.appendChild(shadow);
};

// Method to update the styles of the emoji picker components in the shadow root
export const updatePickerStyles = () => {
  const picker = document.querySelector("em-emoji-picker");
  if (!picker) return;

  const shadowRoot = picker.shadowRoot;
  if (!shadowRoot) return;

  updateShadowRootCssVars();

  if (window.CSSStyleSheet && "replaceSync" in CSSStyleSheet.prototype) {
    try {
      const styleSheet = new CSSStyleSheet();
      styleSheet.replaceSync(PICKER_STYLES);
      shadowRoot.adoptedStyleSheets = [
        ...shadowRoot.adoptedStyleSheets,
        styleSheet,
      ];
    } catch (error) {
      console.error(
        "Error applying styles via Constructable Stylesheets:",
        error,
      );
      applyStylesFallback(shadowRoot);
    }
  } else {
    applyStylesFallback(shadowRoot);
  }
};

const applyStylesFallback = (shadowRoot: ShadowRoot) => {
  let styleElement = shadowRoot.querySelector("style#custom-picker-styles");
  if (!styleElement) {
    styleElement = document.createElement("style");
    styleElement.id = "custom-picker-styles";
    shadowRoot.appendChild(styleElement);
  }
  styleElement.textContent = PICKER_STYLES;
};

const isSelectedEmojiAnIcon = (id: string) => id.includes("icon-");
const isSelectedEmojiAShape = (id: string) => id.includes("shape-");

const generateIconType = (id: string): EmojiType => {
  if (isSelectedEmojiAnIcon(id)) return "icons";
  if (isSelectedEmojiAShape(id)) return "shapes";
  return "emoji";
};

type EmojiType = "emoji" | "icons" | "shapes";

interface Emoji {
  id: string;
  native?: string;
  emoticons?: string[];
}

export const generateSelectedValue = (
  emoji: Emoji,
): { identifier?: string; emoji?: string; emoji_type: EmojiType } => {
  const { id, native, emoticons } = emoji;
  if (isSelectedEmojiAnIcon(id) || isSelectedEmojiAShape(id)) {
    return {
      identifier: id,
      emoji_type: generateIconType(id),
    };
  }
  return {
    emoji: native || emoticons?.[0],
    emoji_type: generateIconType(id),
  };
};
