import type { ReactNode } from "react";
import classNames from "classnames";

export interface IconContainerProps {
  children: ReactNode;
}

export const IconContainer = ({ children }: IconContainerProps) => (
  <div className={classNames("text-dark flex items-center gap-2")}>
    {children}
  </div>
);
