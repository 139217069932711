import { sortShapesByGroupAndColor } from "./utils";

const constructCustomEmojiShape = (name, src) => ({
  id: `shape-${name}`,
  name,
  short_names: [name],
  skins: [
    {
      src,
    },
  ],
});

const requireCircleShapeContext = require.context(
  "@circle-assets/images/emoji-picker-v3/shapes/circle",
  true,
  /^\.\/.*\.png$/,
);

const circleShapes = requireCircleShapeContext.keys().map(key => {
  const name = key.replace("./shape-", "").replace(".png", "");
  return constructCustomEmojiShape(name, requireCircleShapeContext(key));
});

const squareShapeContext = require.context(
  "@circle-assets/images/emoji-picker-v3/shapes/square",
  true,
  /^\.\/.*\.png$/,
);

const squareShapes = squareShapeContext.keys().map(key => {
  const name = key.replace("./shape-", "").replace(".png", "");
  return constructCustomEmojiShape(name, squareShapeContext(key));
});

const diamondShapeContext = require.context(
  "@circle-assets/images/emoji-picker-v3/shapes/diamond",
  true,
  /^\.\/.*\.png$/,
);

const diamondShapes = diamondShapeContext.keys().map(key => {
  const name = key.replace("./shape-", "").replace(".png", "");
  return constructCustomEmojiShape(name, diamondShapeContext(key));
});

const triangleShapeContext = require.context(
  "@circle-assets/images/emoji-picker-v3/shapes/triangle",
  true,
  /^\.\/.*\.png$/,
);

const triangleShapes = triangleShapeContext.keys().map(key => {
  const name = key.replace("./shape-", "").replace(".png", "");
  return constructCustomEmojiShape(name, triangleShapeContext(key));
});

const starShapeContext = require.context(
  "@circle-assets/images/emoji-picker-v3/shapes/star",
  true,
  /^\.\/.*\.png$/,
);

const starShapes = starShapeContext.keys().map(key => {
  const name = key.replace("./shape-", "").replace(".png", "");
  return constructCustomEmojiShape(name, starShapeContext(key));
});

const heartShapeContext = require.context(
  "@circle-assets/images/emoji-picker-v3/shapes/heart",
  true,
  /^\.\/.*\.png$/,
);

const heartShapes = heartShapeContext.keys().map(key => {
  const name = key.replace("./shape-", "").replace(".png", "");
  return constructCustomEmojiShape(name, heartShapeContext(key));
});

export const CUSTOM_SHAPE_DATA_SET = [
  {
    id: "circle",
    name: "Circle",
    emojis: sortShapesByGroupAndColor(circleShapes),
  },
  {
    id: "square",
    name: "Square",
    emojis: sortShapesByGroupAndColor(squareShapes),
  },
  {
    id: "diamond",
    name: "Diamond",
    emojis: sortShapesByGroupAndColor(diamondShapes),
  },
  {
    id: "triangle",
    name: "Triangle",
    emojis: sortShapesByGroupAndColor(triangleShapes),
  },
  {
    id: "star",
    name: "Star",
    emojis: sortShapesByGroupAndColor(starShapes),
  },
  {
    id: "heart",
    name: "Heart",
    emojis: sortShapesByGroupAndColor(heartShapes),
  },
];

export const customEmojiShapeUrl = identifier => {
  const shapeTypeData = CUSTOM_SHAPE_DATA_SET.find(
    shapeType => shapeType.id === identifier.split("-")[1],
  );
  const emoji = shapeTypeData?.emojis?.find(emoji => emoji.id === identifier);

  return emoji?.skins?.[0]?.src;
};
