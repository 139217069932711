import type { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { useEmojiPickerContext } from "./EmojiPickerContext";

interface EmojiPickerWrapperProps {
  shouldEmojiPickerRenderAsPortal?: boolean;
  open?: boolean;
  portalTo?: any;
}

export const EmojiPickerWrapper = ({
  shouldEmojiPickerRenderAsPortal,
  children,
  open,
  portalTo,
}: PropsWithChildren<EmojiPickerWrapperProps>) => {
  const { emojiPickerPopoverRef } = useEmojiPickerContext();

  if (shouldEmojiPickerRenderAsPortal) {
    return (
      <>
        {open &&
          createPortal(
            <div ref={emojiPickerPopoverRef}>{children}</div>,
            portalTo || document.body,
          )}
      </>
    );
  }

  return <div ref={emojiPickerPopoverRef}>{children}</div>;
};
