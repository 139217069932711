import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import type { Option } from "../types";
import { InfoBox } from "./InfoBox";
import { OptionItem } from "./OptionItem";
import "./styles.scss";

export interface RadioGroupBoxProps {
  name: string;
  className?: string;
  rules?: object;
  options: Option[];
  labelOutside?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  optionSize?: "sm" | "md";
  optionAlignment?: "center" | "left" | "row";
  checkedIcon?: React.ReactNode;
  onChange?: (value: string) => void;
}

export const RadioGroupBox = ({
  name,
  className,
  rules,
  options,
  labelOutside = false,
  fullWidth = true,
  disabled = false,
  optionSize,
  optionAlignment,
  checkedIcon,
  onChange,
}: RadioGroupBoxProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange: controllerOnChange, value } }) => (
        <div
          className={classNames({
            "w-full": fullWidth,
          })}
        >
          <RadioGroup
            value={value}
            onChange={value => {
              onChange?.(value);
              controllerOnChange(value);
            }}
            className={classNames(
              "option-group",
              {
                "option-group--full-width": fullWidth,
              },
              className,
            )}
          >
            {options.map(option => (
              <RadioGroup.Option
                key={option.value}
                value={option.value}
                className={classNames({
                  "pointer-events-none": disabled,
                })}
              >
                {({ checked }) => (
                  <OptionItem
                    labelOutside={labelOutside}
                    checked={checked}
                    option={option}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    optionSize={optionSize}
                    optionAlignment={optionAlignment}
                    checkedIcon={checkedIcon}
                  />
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
          {Boolean(value) && <InfoBox value={value} options={options} />}
        </div>
      )}
    />
  );
};
