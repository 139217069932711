import type {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  ReactNode,
} from "react";
import { useEffect, useRef } from "react";
import classNames from "classnames";
import { omit } from "lodash";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";

export interface Option {
  description?: string | ReactNode;
  label: string;
  richLabel?: ReactNode;
  value?: string | number;
}

export interface CheckboxItemProps extends ComponentPropsWithoutRef<"input"> {
  checked: boolean;
  isDescriptionTooltip?: boolean;
  /** Adds extra gap and margin to the component */
  isFormItem?: boolean;
  isRoundedFull?: boolean;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  tooltipClassName?: string;
  name?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  option: Option;
  /** Cannot uncheck component if true */
  uncheckDisabled?: boolean;
  dataTestId?: string;
  /** Sets the indeterminate state on checkbox. This only changes the visual of checkbox, it remains in the checked or unchecked state as provided by checked prop */
  indeterminate?: boolean;
  transformValue?: (value: any) => any;
}

export const CheckboxItem = ({
  checked,
  isDescriptionTooltip = false,
  isFormItem = true,
  isRoundedFull = false,
  className,
  labelClassName,
  inputClassName,
  tooltipClassName,
  name,
  onChange,
  option,
  uncheckDisabled = false,
  indeterminate = false,
  ...rest
}: CheckboxItemProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const { label, richLabel, description, value } = option;
  const id = rest.id || `${name}-${value}`;

  useEffect(() => {
    const checkboxEl = checkboxRef.current;
    if (checkboxEl) {
      checkboxEl.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  const inputProps = omit(rest, ["transformValue"]);

  return (
    <div
      className={classNames(
        "flex",
        {
          "mt-4": isFormItem,
        },
        className,
      )}
    >
      <div
        className={classNames("inline-flex items-start", {
          "gap-3": isFormItem,
        })}
      >
        <div className="flex items-center">
          <input
            ref={checkboxRef}
            className={classNames(
              "text-dark bg-primary hover:bg-tertiary hover:border-secondary disabled:bg-tertiary disabled:border-selector-inactive border-secondary focus:bg-tertiary focus:border-secondary focus:outline-secondary h-4 w-4 cursor-pointer rounded focus:outline-2 focus:ring-0 focus:ring-offset-0",
              {
                "mt-[3px]": isFormItem,
                "rounded-full": isRoundedFull,
              },
              inputClassName,
            )}
            type="checkbox"
            checked={checked}
            onChange={e => {
              if (e.target.checked || !uncheckDisabled) onChange(e);
            }}
            id={id}
            name={name}
            {...inputProps}
          />
        </div>
        <div>
          <div className="flex items-center">
            <label
              className={classNames(
                "text-dark mb-0 w-full cursor-pointer",
                { "cursor-not-allowed opacity-20": inputProps.disabled },
                labelClassName,
              )}
              htmlFor={id}
            >
              {richLabel || label}
            </label>
            {isDescriptionTooltip && (
              <TippyV2 className="flex" content={description}>
                <Icon
                  type="question-mark"
                  className={classNames(
                    "icon icon--xs text-dark ml-2",
                    {
                      "cursor-not-allowed opacity-20": inputProps.disabled,
                    },
                    tooltipClassName,
                  )}
                />
              </TippyV2>
            )}
          </div>
          {!isDescriptionTooltip && (
            <p className="text-default mt-1 text-xs">{description}</p>
          )}
        </div>
      </div>
    </div>
  );
};
