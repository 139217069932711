import type { ComponentPropsWithRef, ReactNode } from "react";
import { forwardRef, useMemo } from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";

export interface WithDescriptionProps extends ComponentPropsWithRef<"input"> {
  id?: string;
  label: ReactNode;
  className?: string;
  labelWrapperClassName?: string;
  labelClassName?: string;
  description?: ReactNode;
  descriptionClassName?: string;
}

export const radioButtonClassNames =
  "bg-primary hover:bg-tertiary focus:ring-secondary focus:bg-primary text-dark border-primary h-4 w-4 cursor-pointer";
export const WithDescription = forwardRef<
  HTMLInputElement,
  WithDescriptionProps
>(
  (
    {
      id,
      label,
      className,
      labelClassName,
      labelWrapperClassName,
      description,
      descriptionClassName,
      ...rest
    },
    ref,
  ) => {
    const inputId = useMemo(() => {
      if (id) return id;
      return uniqueId();
    }, [id]);

    return (
      <div className="relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            id={inputId}
            aria-describedby={`${inputId}-description`}
            type="radio"
            className={classNames(radioButtonClassNames, className)}
            {...rest}
            ref={ref}
          />
        </div>
        <div className={classNames("ml-3 text-sm", labelWrapperClassName)}>
          <label
            htmlFor={inputId}
            className={classNames("text-darkest font-medium", labelClassName)}
          >
            {label}
          </label>
          <p
            id={`${inputId}-description`}
            className={classNames("text-dark", descriptionClassName)}
          >
            {description}
          </p>
        </div>
      </div>
    );
  },
);

WithDescription.displayName = "WithDescription";
