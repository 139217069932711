import { Fragment } from "react";
import PropTypes from "prop-types";
import { Combobox, Transition } from "@headlessui/react";
import classNames from "classnames";

export const Options = ({ onClose, children, className, dataTestId }) => (
  <Transition
    as={Fragment}
    enter="transition ease-in duration-75"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition ease-out duration-75"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    afterLeave={onClose}
  >
    <Combobox.Options
      className={classNames(
        "border-primary bg-primary absolute mt-1 w-full overflow-hidden rounded-lg border shadow-xl focus:outline-none",
        className,
      )}
      data-testid={dataTestId}
    >
      {children}
    </Combobox.Options>
  </Transition>
);

Options.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};
