import classNames from "classnames";
import isFunction from "lodash/isFunction";
import { Typography } from "../../../Typography";

interface ItemProps {
  label: string;
  isActive?: boolean;
  onClick?: () => void;
  className?: string;
}

export const Item = ({ label, isActive, onClick, className }: ItemProps) => (
  <button
    type="submit"
    className={classNames(
      "p-3 no-underline outline-none transition-all duration-100 ease-in-out hover:outline-none focus:outline-none",
      {
        "border-darkest border-b": isActive,
        "border-b border-transparent": !isActive,
      },
      className,
    )}
    onClick={e => {
      e.preventDefault();
      if (isFunction(onClick)) {
        onClick();
      }
    }}
  >
    <Typography.LabelSm
      weight="medium"
      color={isActive ? "text-darkest" : "text-light"}
    >
      {label}
    </Typography.LabelSm>
  </button>
);

Item.displayName = "Tab.Item";
