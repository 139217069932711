import { useQuery } from "react-query";
import { getEmojiMartV5Data } from "./data";

interface EmojiData {
  aliases: string[];
  categories: string[];
  emojis: string[];
  sheet: string[];
}

interface UseEmojiMartDataProps {
  options?: any;
}

export const useEmojiMartData = ({ options }: UseEmojiMartDataProps = {}) =>
  useQuery<EmojiData, Error, EmojiData, string>(
    "emoji-mart-data",
    getEmojiMartV5Data,
    {
      staleTime: 1000 * 60 * 60 * 24,
      ...options,
    },
  );
