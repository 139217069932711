import { Controller, useFormContext } from "react-hook-form";
import type { RegisterOptions } from "react-hook-form";
import { CheckboxItem } from "./CheckboxItem";
import type { Option } from "./CheckboxItem";

export interface CheckboxGroupProps {
  name: string;
  rules?: RegisterOptions;
  options: Option[];
  uncheckDisabled?: boolean;
  isTooltip?: boolean;
  itemClassName?: string;
  itemLabelClassName?: string;
}

export const CheckboxGroup = ({
  name,
  rules,
  options,
  uncheckDisabled,
  isTooltip = false,
  itemClassName = "",
  itemLabelClassName = "",
}: CheckboxGroupProps) => {
  const { control, getValues } = useFormContext();

  const computeNewValue = ({ target: { checked } }: any, value: any) => {
    const currentValue = getValues(name) || [];
    const valueIncluded = currentValue.includes(value);
    if (!checked && valueIncluded) {
      return currentValue.filter((v: any) => v !== value);
    }
    if (checked && !valueIncluded) {
      return [...currentValue, value];
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <>
          {options.map(option => (
            <CheckboxItem
              key={option.value}
              className={itemClassName}
              labelClassName={itemLabelClassName}
              name={name}
              onChange={e => onChange(computeNewValue(e, option.value))}
              option={option}
              checked={value?.includes(option.value) ?? false}
              uncheckDisabled={uncheckDisabled}
              isDescriptionTooltip={isTooltip}
            />
          ))}
        </>
      )}
    />
  );
};

CheckboxGroup.displayName = "Form.CheckboxGroup";
