import { Combobox } from "@headlessui/react";
import classNames from "classnames";

export interface OptionProps {
  option?: any;
  children?: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
}

export const Option = ({
  option = {},
  children,
  className = "",
  ...rest
}: OptionProps) => (
  <Combobox.Option
    className={({ active }) =>
      classNames(
        "text-dark relative cursor-pointer select-none px-5 py-2 text-sm",
        {
          "bg-secondary": active,
        },
        className,
      )
    }
    value={option}
    {...rest}
  >
    {children}
  </Combobox.Option>
);
