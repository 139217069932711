import type { PropsWithChildren } from "react";
import classNames from "classnames";
import { Item } from "./Item";

interface TabProps {
  className?: string;
}

export const Tab = ({ children, className }: PropsWithChildren<TabProps>) => (
  <div className={classNames("flex flex-row px-1", className)}>{children}</div>
);

Tab.displayName = "Tab";
Tab.Item = Item;
