import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { has, trim, uniqBy } from "lodash";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import scrollIntoView from "scroll-into-view-if-needed";
import { useDetectEscapeKeyPress } from "@circle-react/hooks/useDetectEscapeKeyPress";
import { useDropdown } from "@circle-react-shared/DropdownMenu";
import { useFilteredItems } from "@circle-react-uikit/Select/useFilteredItems";
import { InputContainer } from "./InputContainer";
import { MultiSelectInput } from "./MultiSelectInput";
import { OptionsDropdown } from "./OptionsDropdown";
import { SingleSelectInput } from "./SingleSelectInput";
import { ValueAsHiddenFields } from "./ValueAsHiddenFields";
import { useInputController } from "./useInputController";
import "./styles.scss";

export const Select = props => {
  const {
    appendTo,
    autoClearSearchValue = true,
    data,
    direction = "down",
    disabled = false,
    hideIcon = false,
    hideSelectedItems = false,
    shouldFilterSelectedItems = false,
    filterBy,
    showSearchIcon = false,
    minSearchValueLength = 0,
    name,
    notFoundContent = "No matches found.",
    inputContainerClassName,
    onSearch,
    placeholder,
    popperOptions = {},
    variant,
    autoFocus,
    isLoading,
  } = props;
  let { autoClose, addable } = props;
  const multiple = variant === "tags";
  if ([null, undefined].includes(autoClose)) {
    autoClose = !multiple;
  }
  const [isLocalLoading, setIsLocalLoading] = useState(isLoading);
  const searchInputRef = useRef();
  const [searchValue, setSearchValue] = useState("");
  const { value, onChange, isUncontrolledInput } = useInputController(props);
  const selectedItemsCacheRef = useRef(
    data.filter(dataItem => value.includes(dataItem.value)),
  );
  const { open, handleClose, toggleDropdown, handleOpen } = useDropdown();
  const isControlledLoading = has(props, "isLoading");

  useEffect(() => {
    if (isControlledLoading) {
      setIsLocalLoading(isLoading);
    }
  }, [isControlledLoading, isLoading, setIsLocalLoading]);

  const popperElRef = useRef(null);
  const popperTargetRef = useRef(null);
  const { styles, attributes } = usePopper(
    popperTargetRef.current,
    popperElRef.current,
    {
      placement: direction === "up" ? "top-start" : "bottom-start",
      ...popperOptions,
    },
  );

  const shouldUsePortal = Boolean(appendTo);
  const dropdownWidth = popperTargetRef.current?.offsetWidth || 500;
  const dropdownStyles = shouldUsePortal
    ? { ...styles.popper, width: dropdownWidth }
    : {};
  const dropdownAttributes = shouldUsePortal ? attributes.popper : {};
  const showDropdown = open && searchValue.length >= minSearchValueLength;

  useDetectEscapeKeyPress(() => {
    onSearchChange("");
  });

  if (addable === true) {
    addable = value => ({
      text: value,
      value: value,
      listItemText: `Add ${value}`,
    });
  }

  const onSelect = async item => {
    if (multiple) {
      onChange({ value: [...value, item.value], name });
      selectedItemsCacheRef.current = [...selectedItemsCacheRef.current, item];
      scrollIntoView(searchInputRef.current, {
        scrollMode: "if-needed",
        block: "nearest",
        inline: "nearest",
      });
    } else {
      selectedItemsCacheRef.current = [item];
      onChange({ value: item.value, name });
    }
    (await autoClose) ? handleClose() : searchInputRef.current.focus();
    autoClearSearchValue && onSearchChange("");
  };

  // only needed for multiple
  const onDeselect = item => {
    const newValue = value.filter(v => v !== item.value);
    onChange({ value: newValue, name });
    selectedItemsCacheRef.current = selectedItemsCacheRef.current.filter(
      i => i.value !== item.value,
    );
  };

  const onSearchChange = async searchValue => {
    setSearchValue(searchValue);
    !isControlledLoading && setIsLocalLoading(true);
    await onSearch(searchValue);
    !isControlledLoading && setIsLocalLoading(false);
  };

  useEffect(() => {
    open && searchInputRef.current?.focus();
  }, [open]);

  useEffect(() => {
    const handleOutsideClick = evt => {
      if (
        !popperElRef.current?.contains(evt.target) &&
        !popperTargetRef.current?.contains(evt.target)
      ) {
        handleClose();
      }
    };
    if (open) {
      document.addEventListener("click", handleOutsideClick);
    }
    return () => {
      if (open) {
        document.removeEventListener("click", handleOutsideClick);
      }
    };
  }, [open, popperElRef, popperTargetRef, handleClose]);

  let listItems = useFilteredItems(
    data,
    filterBy,
    searchValue,
    shouldFilterSelectedItems,
    value,
  );

  if (addable && !listItems.length && trim(searchValue)) {
    const addableItem = addable(trim(searchValue));
    if (addableItem) {
      listItems = [addableItem];
    }
  }

  const selectedItems = uniqBy(
    [...selectedItemsCacheRef.current, ...data],
    "value",
  ).filter(dataItem => value.includes(dataItem.value));

  const renderList = (
    <div ref={popperElRef}>
      {showDropdown && (
        <OptionsDropdown
          direction={direction}
          items={listItems}
          onSelect={onSelect}
          loading={isLocalLoading}
          notFoundContent={notFoundContent}
          style={dropdownStyles}
          {...dropdownAttributes}
        />
      )}
    </div>
  );

  const SelectInput = multiple ? MultiSelectInput : SingleSelectInput;
  // minSearchValueLength > 0 that means dropdown will not be visible on click, in those cases we want to use editIcon vs cheveron
  const useEditIcon = props.useEditIcon || minSearchValueLength >= 1;

  return (
    <div className={classnames("react-select", { "!z-10": showDropdown })}>
      {showDropdown && !shouldUsePortal && direction === "up" && renderList}
      {isUncontrolledInput() && (
        <ValueAsHiddenFields value={value} multiple={multiple} name={name} />
      )}
      <InputContainer
        ref={popperTargetRef}
        open={open}
        showDropdown={showDropdown}
        toggleDropdown={toggleDropdown}
        useEditIcon={useEditIcon}
        hideIcon={hideIcon}
        searchValue={searchValue}
        value={value}
        disabled={disabled}
        showSearchIcon={showSearchIcon}
        inputContainerClassName={inputContainerClassName}
      >
        <SelectInput
          searchInputRef={searchInputRef}
          searchValue={searchValue}
          open={open}
          selectedItems={selectedItems}
          onSearchChange={onSearchChange}
          toggleDropdown={toggleDropdown}
          handleOpen={handleOpen}
          placeholder={placeholder}
          onDeselect={onDeselect}
          disabled={disabled}
          hideSelectedItems={hideSelectedItems}
          autoFocus={autoFocus}
        />
      </InputContainer>
      {showDropdown && !shouldUsePortal && direction === "down" && renderList}
      {shouldUsePortal && createPortal(renderList, appendTo)}
    </div>
  );
};

Select.propTypes = {
  appendTo: PropTypes.any,
  variant: PropTypes.oneOf(["single", "tags"]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  direction: PropTypes.oneOf(["down", "up"]),
  addable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  autoClearSearchValue: PropTypes.bool,
  minSearchValueLength: PropTypes.number,
  useEditIcon: PropTypes.bool,
  hideIcon: PropTypes.bool,
  notFoundContent: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  autoClose: PropTypes.bool,
  onSearch: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hideSelectedItems: PropTypes.bool,
  popperOptions: PropTypes.object,
  autoFocus: PropTypes.bool,
  shouldFilterSelectedItems: PropTypes.bool,
  filterBy: PropTypes.func,
  showSearchIcon: PropTypes.bool,
};
