import PropTypes from "prop-types";
import classNames from "classnames";

export const EmptyOption = ({ label = "", className }) => (
  <div
    className={classNames(
      "text-light relative cursor-default select-none px-5 py-2 text-sm",
      className,
    )}
  >
    {label}
  </div>
);

EmptyOption.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
};
