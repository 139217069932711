import type { FieldValues, Path } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import { resolveCssVariable } from "../../../BuilderUI/ThemeConfiguration/SiteStyles/Tokens/utils/resolveCssVariable";
import { ColorPickerV2 } from "../../../ColorPickerV2";

interface FormColorPickerProps<T extends FieldValues> {
  name: Path<T>;
  shouldResolveCssVariable?: boolean;
  cssVariables?: Record<string, string>;
  shouldRenderThemeColors?: boolean;
  themeColors?: Record<string, string>;
  customColors?: Record<string, string>;
  onAddCustomColor?: (color: string) => void;
}

export const FormColorPickerV2 = <T extends FieldValues>({
  name,
  shouldResolveCssVariable = false,
  cssVariables,
  shouldRenderThemeColors = false,
  themeColors,
  customColors,
  onAddCustomColor,
  ...passThroughProps
}: FormColorPickerProps<T>) => {
  const { control } = useFormContext<T>();

  return (
    <Controller<T>
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ColorPickerV2
          {...passThroughProps}
          initialColor={
            shouldResolveCssVariable
              ? resolveCssVariable({
                  value,
                  cssVariables: cssVariables || {},
                })
              : value
          }
          onColorChange={color => onChange(color)}
          shouldRenderThemeColors={shouldRenderThemeColors}
          themeColors={themeColors}
          customColors={customColors}
          onAddCustomColor={onAddCustomColor}
          cssVariables={cssVariables}
        />
      )}
    />
  );
};
