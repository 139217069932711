import { t } from "@/i18n-js/instance";

export interface EmojiPickerHeaderTabItem {
  label: string;
  value: string;
}

export const TAB_ITEMS: EmojiPickerHeaderTabItem[] = [
  {
    label: t("emoji_picker_v3.tabs.emoji"),
    value: "emoji",
  },
  {
    label: t("emoji_picker_v3.tabs.shapes"),
    value: "shapes",
  },
  {
    label: t("emoji_picker_v3.tabs.icon"),
    value: "icons",
  },
  {
    label: t("emoji_picker_v3.tabs.custom"),
    value: "custom",
  },
];
