import { useState } from "react";
import { groupBy, isFunction } from "lodash";

export const useSearchOptions = (
  options: any,
  filterBy: any,
  groupByValue: any,
  onSearchChange: any,
) => {
  const [search, setSearch] = useState("");

  const clearSearch = () => setSearch("");

  const handleSearchChange = (value: any) => {
    setSearch(value);
    onSearchChange?.(value);
  };

  const searchResults = filterBy(options, search);
  const hasNoResults =
    (searchResults.length === 0 && search !== "") || options.length === 0;
  const shouldGroup = isFunction(groupByValue) && !hasNoResults;
  const groupedSearchResults = shouldGroup
    ? groupBy(searchResults, groupByValue)
    : null;
  const hasGroups = Boolean(
    groupedSearchResults && Object.keys(groupedSearchResults).length > 0,
  );

  return {
    search,
    clearSearch,
    handleSearchChange,
    searchResults,
    hasNoResults,
    groupedSearchResults,
    hasGroups,
  };
};
