/*
  emoji-mart sorts the emojis internally based on the "name" property in the data. While sorting, they just consider the element at first index of the name.
  So, if we have names "1-red", "2-blue" and "10-green", the order will be "1-red", "10-green", "2-blue".
  To avoid this, we are adding a prefix to the name based on the group and color of the emoji.
  In order to maintain the correct order for shades of grey, we have added a custom order for them too.
*/
export const CUSTOM_COLOR_ORDER_FOR_SORTING = {
  red: "01",
  orange: "02",
  yellow: "03",
  lime: "04",
  green: "05",
  aquamarine: "06",
  cyan: "07",
  blue: "08",
  navy: "09",
  indigo: "10",
  purple: "11",
  magenta: "12",
  grey25: "13",
  grey50: "14",
  grey100: "15",
  grey200: "16",
  grey300: "17",
  grey400: "18",
  grey500: "19",
  grey600: "20",
  grey700: "21",
  grey800: "22",
  grey900: "23",
  grey1000: "24",
};
