import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import type { MutableRefObject, ReactNode } from "react";
import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { noop } from "lodash";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { focusFirstFocusableElement } from "./utils";

export const HEADLESS_UI_POPOVER_ROOT = "headless-ui-popover-root";

interface PopoverContextProps {
  onClose: (
    focusableElement?:
      | HTMLElement
      | MutableRefObject<HTMLElement | null>
      | undefined,
  ) => void;
}

const PopoverPortalContext = createContext<PopoverContextProps>({
  onClose: noop,
});
PopoverPortalContext.displayName = "PopoverPortalContext";

export const usePopoverPortalContext = () => useContext(PopoverPortalContext);

export type PopperOptions = Parameters<typeof usePopper>[2];

export type Placement =
  | "auto"
  | "auto-start"
  | "auto-end"
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "right"
  | "right-start"
  | "right-end"
  | "left"
  | "left-start"
  | "left-end";

export interface PopoverPortalProps {
  children: ReactNode;
  button?: ReactNode;
  renderButton?: (isOpen: boolean) => ReactNode;
  placement?: Placement;
  disabled?: boolean;
  onOpen?: () => void;
  className?: string;
  popperOptions?: PopperOptions;
  handleClose?: () => void;
  shouldHideOnMdScreens?: boolean;
  shouldCloseOnLocationChange?: boolean;
}

export const PopoverPortal = ({
  children,
  button,
  renderButton,
  placement = "right-end",
  disabled = false,
  onOpen,
  className,
  popperOptions,
  handleClose,
  shouldHideOnMdScreens = true,
}: PopoverPortalProps) => {
  const [isDomReady, setDomReady] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    ...popperOptions,
  });

  useEffect(() => {
    setDomReady(true);
  }, []);

  useLayoutEffect(() => {
    if (popperElement) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  });

  useEffect(() => {
    if (popperElement && onOpen) {
      onOpen();
    }
    if (!popperElement && handleClose) {
      handleClose();
    }
    const focusElement = popperElement || referenceElement;
    focusFirstFocusableElement(focusElement);
  }, [popperElement]);

  const hasButton = Boolean(button || renderButton);

  if (!isDomReady && !hasButton) {
    return null;
  }

  return (
    <Popover>
      {({ open, close }) => {
        const contextProps = { onClose: close };
        const portalRoot = document.getElementById(HEADLESS_UI_POPOVER_ROOT);

        return (
          <PopoverPortalContext.Provider value={contextProps}>
            <Popover.Button
              as="div"
              ref={setReferenceElement}
              className={classNames("w-full", {
                "rounded-md": open,
              })}
            >
              {button ?? renderButton?.(open)}
            </Popover.Button>
            {!disabled &&
              portalRoot &&
              createPortal(
                <Popover.Panel
                  as="div"
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className={classNames(
                    "border-primary bg-primary z-50 -ml-2.5 h-[600px] w-[496px] rounded-lg border shadow-xl lg:block 2xl:h-[658px]",
                    {
                      hidden: shouldHideOnMdScreens,
                    },
                    className,
                  )}
                >
                  {children}
                </Popover.Panel>,
                portalRoot,
              )}
          </PopoverPortalContext.Provider>
        );
      }}
    </Popover>
  );
};
