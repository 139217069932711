export const filterByLabel = (options = [], search = "") => {
  if (!search) {
    return [...options];
  }

  return options.filter(option =>
    (typeof option.label === "string" ? option.label : option.textLabel)
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(search.toLowerCase().replace(/\s+/g, "")),
  );
};
