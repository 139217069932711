import classNames from "classnames";

export interface FormLabelProps {
  className?: string;
  fieldId: string;
  label: string;
  labelClassName?: string;
  description?: string;
  hideBorder?: boolean;
  inline?: boolean;
}

export const FormLabel = ({
  className = "",
  fieldId,
  label,
  labelClassName,
  description,
  hideBorder = false,
  inline = false,
}: FormLabelProps) => (
  <div
    className={classNames(
      "form-group",
      {
        "no-border": hideBorder,
        "form-group--inline": inline,
      },
      className,
    )}
  >
    <label
      className={classNames(
        "form-group__label text-dark font-semibold",
        labelClassName,
      )}
      htmlFor={fieldId}
    >
      {label}
    </label>
    {description && <div className="field-description">{description}</div>}
  </div>
);

FormLabel.displayName = "Form.Label";
