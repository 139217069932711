import { createContext, useContext, useEffect, useState } from "react";
import type { Dispatch, ReactNode, SetStateAction } from "react";

export interface ModalNestingContextShape {
  totalNesting: number;
  setTotalNesting: Dispatch<SetStateAction<number>>;
  setHadNesting: Dispatch<SetStateAction<boolean>>;
  hadNesting: boolean;
}

const ModalNesting = createContext<ModalNestingContextShape | null>(null);

ModalNesting.displayName = "ModalNesting";

const useModalNestingContext = () => useContext(ModalNesting);

export const useModalNesting = () => {
  const nestingContext = useModalNestingContext();

  if (!nestingContext) return { totalNesting: 0, hadNesting: false };

  const { totalNesting, hadNesting } = nestingContext;

  return { totalNesting, hadNesting };
};

export interface ModalNestingContextProps {
  children: ReactNode;
}

export const ModalNestingContext = ({ children }: ModalNestingContextProps) => {
  const [totalNesting, setTotalNesting] = useState(0);
  const [hadNesting, setHadNesting] = useState(false);
  const nestingContext = useModalNestingContext();
  const hasNestingContext = nestingContext !== null;

  useEffect(() => {
    if (!hasNestingContext) return;
    const { setTotalNesting, setHadNesting } = nestingContext;

    setTotalNesting(total => total + 1);
    setHadNesting(() => true);
    return () => setTotalNesting(total => total - 1);
  }, []);

  if (hasNestingContext) {
    return <>{children}</>;
  }

  return (
    <ModalNesting.Provider
      value={{ totalNesting, setTotalNesting, setHadNesting, hadNesting }}
    >
      {children}
    </ModalNesting.Provider>
  );
};
