import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Prompt } from "react-router-dom";
import { t } from "@/i18n-js/instance";

export interface PreventAccidentalLeaveProps {
  message?: string;
}

export const PreventAccidentalLeave = ({
  message,
}: PreventAccidentalLeaveProps) => {
  const {
    formState: { isDirty, isSubmitSuccessful },
  } = useFormContext();
  const shouldWarnLeave = isDirty && !isSubmitSuccessful;
  const warnMessage = message || t("prevent_accidental_leave_message");

  useEffect(() => {
    const beforeUnloadHandler = (event: any) => {
      if (shouldWarnLeave) {
        event.preventDefault();
        event.returnValue = warnMessage;
      }
    };
    const beforePageChangeHandler = (event: any) => {
      // eslint-disable-next-line no-alert -- The confirm dialog is intentional here
      if (shouldWarnLeave && !window.confirm(warnMessage)) {
        event.preventDefault();
      }
    };
    window.addEventListener("beforeunload", beforeUnloadHandler);
    document.addEventListener(
      "turbolinks:before-visit",
      beforePageChangeHandler,
    );

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
      document.removeEventListener(
        "turbolinks:before-visit",
        beforePageChangeHandler,
      );
    };
  }, [shouldWarnLeave, warnMessage]);

  return <Prompt when={shouldWarnLeave} message={warnMessage} />;
};
