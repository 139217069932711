import type { ReactNode } from "react";
import classNames from "classnames";

export interface OptionsScrollableContainerProps {
  children: ReactNode;
  className?: string;
}

export const OptionsScrollableContainer = ({
  children,
  className,
}: OptionsScrollableContainerProps) => (
  <div
    className={classNames(
      "text-dark h-full max-h-60 w-full list-none overflow-auto py-2",
      className,
    )}
  >
    {children}
  </div>
);
