import { useEffect, useRef, useState } from "react";
import type { LegacyRef } from "react";
import { usePopper } from "react-popper";
import type { PopperProps } from "react-popper";
import { useMutationObserver } from "@circle-react/hooks/useMutationObserver";
import { TAB_ITEMS } from "../constants";
import type { EmojiPickerHeaderTabItem } from "../constants";

type UsePopperReturn = ReturnType<typeof usePopper>;
type UsePopperStyles = Partial<UsePopperReturn["styles"]>;
type UsePopperAttributes = Partial<UsePopperReturn["attributes"]>;

export interface EmojiPickerState {
  activeTab: EmojiPickerHeaderTabItem;
  setActiveTab: (tab: EmojiPickerHeaderTabItem) => void;
  popperElement: HTMLElement | null;
  setPopperElement: (element: HTMLElement | null) => void;
  targetElement: HTMLElement | null;
  setTargetElement: (element: HTMLElement | null) => void;
  emojiPickerPopoverRef?: LegacyRef<HTMLDivElement> | null;
  isEmojiPickerTabActive: boolean;
  isIconPickerTabActive: boolean;
  isShapePickerTabActive: boolean;
  isCustomPickerTabActive: boolean;
  shouldRenderEmojiMartPicker: boolean;
  styles: UsePopperStyles;
  attributes: UsePopperAttributes;
  hasElementMutated: boolean;
  setHasElementMutated: (value: boolean) => void;
  EMOJI_PICKER_ENABLED_TABS_MAPPER: Record<string, boolean>;
  isCustomFileUploadProcessing: boolean;
  setIsCustomFileUploadProcessing: (value: boolean) => void;
}

interface UseEmojiPickerOptions {
  pickerAddOns: string[];
  placement: PopperProps<any>["placement"];
  popperOptions: PopperProps<any>["modifiers"];
}

export const useEmojiPicker = ({
  pickerAddOns,
  placement,
  popperOptions,
}: UseEmojiPickerOptions): EmojiPickerState => {
  const [activeTab, setActiveTab] = useState<EmojiPickerHeaderTabItem>(
    TAB_ITEMS[0],
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [isCustomFileUploadProcessing, setIsCustomFileUploadProcessing] =
    useState(false);

  const emojiPickerPopoverRef = useRef<HTMLDivElement>(null);

  const checkIfPickerTabIsEnabled = (tab: string) =>
    pickerAddOns?.includes(tab);

  const isEmojiPickerTabEnabled = true;
  const isIconPickerTabEnabled = checkIfPickerTabIsEnabled("icons");
  const isShapePickerTabEnabled = checkIfPickerTabIsEnabled("shapes");
  const isCustomPickerTabEnabled = checkIfPickerTabIsEnabled("custom");

  const isEmojiPickerTabActive =
    isEmojiPickerTabEnabled && activeTab.value === "emoji";
  const isIconPickerTabActive =
    isIconPickerTabEnabled && activeTab.value === "icons";
  const isShapePickerTabActive =
    isShapePickerTabEnabled && activeTab.value === "shapes";
  const isCustomPickerTabActive =
    isCustomPickerTabEnabled && activeTab.value === "custom";

  const shouldRenderEmojiMartPicker =
    isEmojiPickerTabActive || isIconPickerTabActive || isShapePickerTabActive;

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    strategy: "fixed",
    placement: placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
    ...popperOptions,
  });

  const { hasElementMutated, setHasElementMutated } = useMutationObserver(
    emojiPickerPopoverRef,
    {
      childList: true,
      subtree: true,
      attributes: true,
    },
  );

  const EMOJI_PICKER_ENABLED_TABS_MAPPER = {
    emoji: isEmojiPickerTabEnabled,
    shapes: isShapePickerTabEnabled,
    icons: isIconPickerTabEnabled,
    custom: isCustomPickerTabEnabled,
  };

  useEffect(() => {
    if (!hasElementMutated) return;
    setHasElementMutated(hasElementMutated => !hasElementMutated);
  }, [hasElementMutated]);

  return {
    activeTab,
    setActiveTab,
    popperElement,
    setPopperElement,
    setTargetElement,
    targetElement,
    emojiPickerPopoverRef,
    isEmojiPickerTabActive,
    isIconPickerTabActive,
    isShapePickerTabActive,
    isCustomPickerTabActive,
    shouldRenderEmojiMartPicker,
    styles,
    attributes,
    hasElementMutated,
    setHasElementMutated,
    EMOJI_PICKER_ENABLED_TABS_MAPPER,
    isCustomFileUploadProcessing,
    setIsCustomFileUploadProcessing,
  };
};
